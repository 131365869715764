export const LEISURE                           = 'leisure';
export const BUSINESS                          = 'business';
export const SIGNEDGUEST_NO_MELDESCHEIN_NEEDED = 'noMeldescheinNeeded';
// Header steps
export const HEADER_STEP_CHECK                 = "check";
export const HEADER_STEP_PAYMENT               = "payment";
export const HEADER_STEP_KEYCARD               = "keycard";
export const HEADER_STEP_READY                 = "ready";
// pages
export const PAGE_START                        = "start";
export const PAGE_MAIN                         = "main";
export const PAGE_ADDRESS                      = "address";
export const PAGE_CROSSSELLING                 = "crossselling";
export const PAGE_CROSSSELLING_ADDED           = "crossselling_added";
export const PAGE_REGISTER                     = "register";
export const PAGE_ARBEITGEBERBESTAETIGUNG      = "arbeitgeberbestaetigung";
export const PAGE_PAYMENT                      = "payment";
export const PAGE_KEYCARD                      = "keycard";
export const PAGE_LOSTCARD                     = "lostcard";
export const PAGE_THANKYOU                     = "thankyou";
export const PAGE_LOGIN                        = "login";
export const PAGE_CITYTAX                      = "citytax";
/**
 * @deprecated
 * @type {string}
 */
export const PAGE_ROOMINFORAMTION              = PAGE_KEYCARD;
// messages
export const MESSAGE_LOGIN_OUTOFTIME           = 'outOfTime';
export const MESSAGE_LOGIN_CHECKINDISABLED     = 'checkinDisabled';
export const MESSAGE_LOGIN_NOMATCH             = 'noMatch';
export const MESSAGE_LOGIN_NOTSUPPORTED        = 'notSupported';

export const APALEO_SERVICE_TYPE_OTHER            = 'Other';
export const APALEO_SERVICE_TYPE_ACCOMMODATION    = 'Accommodation';
export const APALEO_SERVICE_TYPE_FOODANDBEVERAGES = 'FoodAndBeverages';
export const APALEO_SERVICE_TYPE_CANCELLATIONFEES = 'CancellationFees';
export const APALEO_SERVICE_TYPE_NOSHOW           = 'NoShow';
export const APALEO_SERVICE_TYPE_CITYTAX          = 'CityTax';

// unit state
export const CHECKIN_STATE_OCCUPIED  = 'occupied';
export const CHECKIN_STATE_NOT_READY = 'notReady';
export const CHECKIN_STATE_READY     = 'ready';

