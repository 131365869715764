import React, {Component}                from 'react';
import Translator                        from "../../components/Translator";
import Header                            from "../../components/Header";
import Footer                            from "../../components/Footer";
import Icon                              from "../../components/Icon";
import {withRouter}                      from "react-router";
import {connect}                         from "react-redux";
import Modal                             from "react-modal";
import {goToLoginPage, goToLostcardPage} from "../Types/Dispatcher";
import {PAGE_MAIN}                       from "../../constants/constants";
import QRCode                            from "react-qr-code";

class Main extends Component
{
    constructor(props)
    {
        super(props);
        this.state      = {
            modalIsOpen: false
        };
        this.openModal  = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal()
    {
        this.setState({modalIsOpen: true});
    }

    closeModal()
    {
        this.setState({modalIsOpen: false});
    }

    render()
    {
        let nameElement = '';

        if (typeof this.props.hotelConfig !== 'undefined' && typeof this.props.hotelConfig.name !== 'undefined') {
            nameElement = this.props.hotelConfig.name[this.props.locale];
        }

        return (<div className="page page-main">
            <Header />
            <div className="app-content">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="page-header">
                                <Translator content={"main.description"} with={{hotel: nameElement}} /><br />
                            </div>
                        </div>
                        <div className="col-4 offset-4">
                            <div className={'action-menu'}>
                                <button
                                    className="btn btn-primary"
                                    onClick={() => {
                                        goToLoginPage(PAGE_MAIN);
                                    }}
                                >
                                    <Translator content={"main.checkinLabel"} />
                                </button>

                                {(() => {
                                    if (this.props.pagesConfig.enableBookingButton) {
                                        return <button
                                            className="btn btn-secondary"
                                            onClick={this.openModal}
                                        >
                                            <Translator content={"main.booking"} />
                                        </button>;
                                    }
                                })()}
                                {(() => {
                                    if (this.props.pagesConfig.enableLostkeyWorkflow) {
                                        return <button
                                            className="btn btn-secondary"
                                            onClick={() => {
                                                goToLostcardPage(PAGE_MAIN)
                                            }}
                                        >
                                            <Translator content={"main.lostcardLabel"} />
                                        </button>;
                                    }
                                })()}
                            </div>
                        </div>

                        {this.props.pagesConfig.enableBookingButton && <Modal
                            className={'booking-modal'}
                            isOpen={this.state.modalIsOpen}
                            onRequestClose={() => {
                                this.closeModal()
                            }}
                            contentLabel=""
                        >
                            <div onClick={() => {
                                this.closeModal()
                            }} className="modal-close">
                                <Icon icon="clear" />
                            </div>
                            <div className="modal-headline">
                                <Translator content={'bookingModal.headline'}/>
                            </div>
                            <div className="modal-content">
                                <div className="bodytext-app">
                                    <Translator content={'bookingModal.text'}/>
                                </div>
                                <div className={'__svg-wrap'}>
                                    <QRCode size={200} value={this.props.specificallyCustomerInformation.bookingUrl} />
                                </div>
                            </div>
                        </Modal>}
                    </div>
                </div>
            </div>
            <Footer
                showCancel={false}
            />
        </div>);
    }
}

export default withRouter(connect(state => {
    return {
        pagesConfig:                     state.app.pagesConfig,
        hotelConfig:                     state.app.hotelConfig,
        locale:                          state.app.locale,
        specificallyCustomerInformation: state.app.specificallyCustomerInformation
    }
}, {})(Main));
