import React, {useState} from "react";
import Translator        from "../../Translator";
import Icon              from "../../Icon";
import Price             from "../../Price";
import {connect}         from "react-redux";
import {Collapse}        from 'react-collapse';
import ServiceDetails    from "../ServiceDetails";
import ApaleoTranslator  from "../../ApaleoTranslator";

const ReservationDetails = (props) => {
    const [modalIsOpen, toggleModal] = useState(false);

    let reservation  = props.reservation;
    let folio        = reservation._meta.folio;
    let currencyCode = props.hotelConfig.currencyCode;
    let icons        = [];
    for (let i = 0; i < reservation.adults; i++) {
        icons.push(<Icon key={i} icon={'MOOI_Icon_Person'} />);
    }

    return <>
        <div className={'__reservation-details'}>
            <div className="__guest-col">
                <div className={'__headline'}>
                    {!!reservation.primaryGuest.title ? reservation.primaryGuest.title + ' ' : ''}
                    {!!reservation.primaryGuest.firstName ? reservation.primaryGuest.firstName + ' ' : ''}
                    {!!reservation.primaryGuest.lastName ? reservation.primaryGuest.lastName : ''}
                </div>
                {icons}
            </div>
            <div className="__price-col">
                <div className={'__headline'}>
                    <ApaleoTranslator property={reservation.unitGroup.name} />
                    {(() => {
                        if (folio !== null && folio.allowedPayment > 0) {
                            return <span>
                            <Translator content={'words.priceToBePaid'} />: <Price
                                currency={currencyCode} amount={folio.allowedPayment} />
                    </span>;
                        }
                    })()}
                </div>
            </div>
        </div>

        {(() => {
            if (folio !== null && folio.allowedPayment > 0) {
                return <div className={'__reservation-services'}>
                    <div className={'__show-details' + (modalIsOpen ? ' _open' : '')} onClick={() => {
                        toggleModal(!modalIsOpen)
                    }}>
                        <Icon icon={'MOOI_Icon_Pfeil-unten'} size={20} />
                        <Translator content={'button.showDetails'} />
                    </div>
                    <Collapse isOpened={modalIsOpen}>
                        <ServiceDetails />
                    </Collapse>
                </div>;
            }
        })()}
    </>;
}

export default connect(state => {
    return {
        hotelConfig:       state.app.hotelConfig,
        meldescheinConfig: state.app.meldescheinConfig,
        reservation:       state.apaleo.reservation,
        pagesConfig:       state.app.pagesConfig,
        locale:            state.app.locale
    }
}, {})(ReservationDetails);
