import React from "react";

let currencyFormatter = require('currency-formatter');
let counterpart       = require('counterpart');

const Price = (props) => {
    return <span>{currencyFormatter.format(props.amount, {
        code:   props.currency,
        locale: counterpart.getLocale()
    })}</span>
}

Price.defaultProps = {
    amount:   0,
    currency: 'EUR'
};

export default Price;
