import React, {useState} from "react";
import Icon from "../Icon";
import {connect, useDispatch, useSelector} from "react-redux";
import Modal from "react-modal";
import Translator from "../Translator";
import {addServices, addServiceToCart, emptyCart, toggleSpinner} from "../../redux/actions";
import ExtrasCart from "../Cart/ExtrasCart";
import ExtrasSelection from "../Cart/ExtrasSelection";
import * as Sentry from "@sentry/browser";

const AddExtrasModal = (props) => {
    const services = useSelector((state) => state.cart.services);
    const guestCredentials = useSelector((state) => state.apaleo.guestCredentials);
    const dispatch = useDispatch();
    const [isCart, setIsCart] = useState(false)

    const toggleModalState = () => {
        setIsCart(!isCart)
    }

    const bookExtras = () => {
        dispatch(addServices(guestCredentials.bookingId, guestCredentials.lastName, services))
            .then((response) => {
                props.closeModal();
            })
            .catch(function (err) {
                Sentry.captureException(err);
                dispatch(toggleSpinner(false, ''));
            })
    }

    const handleClose = () => {
        setIsCart(false)
        dispatch(emptyCart())
        props.closeModal()
    }

    return (<Modal
        className="add-extras-modal"
        isOpen={props.isModalOpen}
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleClose}
    >
        <div onClick={handleClose} className="modal-close">
            <Icon icon="MOOI_x"/>
        </div>
        {isCart
         ? <ExtrasCart/>
         : <ExtrasSelection/>
        }
        <div className="button-wrapper">
            <button onClick={!isCart ? handleClose : toggleModalState} className="btn btn-secondary">
                <Translator content={!isCart ? 'button.cancel' : 'button.backToList'}/>
            </button>
            <button disabled={isCart && (services.length === 0)} onClick={!isCart ? toggleModalState : bookExtras}
                    className="btn btn-primary">
                <Translator content={!isCart ? 'button.cart' : 'button.addExtras'}/>
            </button>
        </div>
    </Modal>);
}

AddExtrasModal.defaultProps = {
    isModalOpen: false,
};

export default (connect(state => {
    return {
        serviceOffers: state.apaleo.serviceOffers,
        cartServices: state.cart.services
    }
}, {addServiceToCart})(AddExtrasModal));
