import React, {Component} from "react";
import PropTypes          from 'prop-types';
import langEn             from "../languages/en";
import langDe             from "../languages/de";
import theLogo            from '../config/logo.svg';
import Icon               from "./Icon";

import i18next      from "i18next";
import LanguageMenu from "./LanguageMenu";
import {withRouter} from 'react-router-dom';

let counterpart = require('counterpart');
counterpart.registerTranslations('en', langEn);
counterpart.registerTranslations('de', langDe);

class Header extends Component
{

    changeLanguage(e, lang)
    {
        e.preventDefault();
        counterpart.setLocale(lang);
        i18next.changeLanguage(lang)
        this.setState({lang: lang});
    }


    render()
    {
        const location = this.props.location.pathname

        return (<div className={'app-header'}>
            <div className={'container'}>
                <div className={'logo'}>
                    <img src={theLogo} alt={'.detco'} height={'40px'}/>
                </div>
                <div className={'steps' + (this.props.showSteps < 1 ? ' d-none' : '')}>
                    <div className={'__step' + (this.props.showSteps > 0 ? ' active' : '')}>1</div>
                    <div className={'__step' + (this.props.showSteps > 1 ? ' active' : '')}>2</div>
                    <div className={'__step' + (this.props.showSteps > 2 ? ' active' : '')}>3</div>
                    <div className={'__step' + (this.props.showSteps > 3 ? ' active' : '')}>4</div>
                </div>

                {(location !== '/') ? (<div className={'language-selection'}>
                    <LanguageMenu/>
                </div>) : false}
            </div>
        </div>);
    }
}

// Definition of LabeledInput component properties
Header.propTypes = {
    showSteps: PropTypes.number // Optional css class name string
};

// Definition of default LabeledInput component properties
Header.defaultProps = {
    showSteps: 0
};

export default withRouter(Header);
