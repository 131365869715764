import PropTypes          from 'prop-types';
import React, {Component} from "react";
import Translator         from "../../components/Translator";

class BackButton extends Component
{

    static contextTypes = {
        router: PropTypes.object.isRequired
    };

    render()
    {
        return (<button className="back-button btn btn-secondary btn-uppercase btn-space"
                        onClick={this.context.router.history.goBack}>
            <Translator content={"start.goback"}/>
        </button>);
    }
}

export default BackButton;
