import React                                   from "react";
import {
    APALEO_SERVICE_TYPE_ACCOMMODATION,
    APALEO_SERVICE_TYPE_CANCELLATIONFEES,
    APALEO_SERVICE_TYPE_CITYTAX,
    APALEO_SERVICE_TYPE_FOODANDBEVERAGES,
    APALEO_SERVICE_TYPE_NOSHOW,
    APALEO_SERVICE_TYPE_OTHER
}                                              from "../../constants/constants";
import Price                                   from "../Price";
import {getRefundedChargesList, reduceCharges} from "../../Helper/ReduceCharges";
import Translator                              from "../Translator";
import {connect}                               from "react-redux";
import md5                                     from "uuid/dist/esm-node/md5";
import moment                                  from "moment/moment";
/*TODO: neues feaure: timeslices aufteilen*/
// import Icon                                    from "../Icon";
// import {Collapse}                              from 'react-collapse';

const ServiceDetails = (props) => {
    let folio = props.reservation._meta.folio;

    if (folio === null) {
        return null;
    }

    let counterpart = require('counterpart');
    moment.locale(counterpart.getLocale());

    //TODO: merge generateGroupedServicesForOverview and groupCharges
    let generateGroupedServicesForOverview = () => {
        let refundedCharges = getRefundedChargesList(folio);

        // merge services by type
        let reduced = folio.charges.reduce((p, charge) => {
            if (!refundedCharges.includes(charge.id)) {
                return reduceCharges(p, charge.serviceType, charge, props.locale);
            } else {
                return p;
            }
        }, {});

        let groupedServices = {};

        if (reduced.hasOwnProperty(APALEO_SERVICE_TYPE_ACCOMMODATION)) {
            groupedServices.accommodation = reduced[APALEO_SERVICE_TYPE_ACCOMMODATION];
        }
        if (reduced.hasOwnProperty(APALEO_SERVICE_TYPE_CITYTAX)) {
            groupedServices.citytax = reduced[APALEO_SERVICE_TYPE_CITYTAX];
        }

        // merge other servicetypes to other
        groupedServices.extras = {
            name:        <Translator content={"details.additionalservices"} />,
            count:       0,
            serviceType: "MIXED",
            price:       0,
            currency:    ''
        }

        if (reduced.hasOwnProperty(APALEO_SERVICE_TYPE_FOODANDBEVERAGES)) {
            groupedServices.extras.price += reduced[APALEO_SERVICE_TYPE_FOODANDBEVERAGES].price;
            groupedServices.extras.count += 1;
        }
        if (reduced.hasOwnProperty(APALEO_SERVICE_TYPE_CANCELLATIONFEES)) {
            groupedServices.extras.price += reduced[APALEO_SERVICE_TYPE_CANCELLATIONFEES].price;
            groupedServices.extras.count += 1;
        }
        if (reduced.hasOwnProperty(APALEO_SERVICE_TYPE_NOSHOW)) {
            groupedServices.extras.price += reduced[APALEO_SERVICE_TYPE_NOSHOW].price;
            groupedServices.extras.count += 1;
        }
        if (reduced.hasOwnProperty(APALEO_SERVICE_TYPE_OTHER)) {
            groupedServices.extras.price += reduced[APALEO_SERVICE_TYPE_OTHER].price;
            groupedServices.extras.count += 1;
        }

        return groupedServices;
    }
    let groupedServices                    = generateGroupedServicesForOverview();
    let groupCharges                       = (serviceType) => {
        let refundedCharges = getRefundedChargesList(folio);

        let reduced = folio.charges.reduce((p, charge) => {
            if (!refundedCharges.includes(charge.id)) {
                if (charge.serviceType === serviceType) {
                    p = reduceCharges(p, md5(charge.name), charge, props.locale);
                }
                return p;
            } else {
                return p;
            }
        }, {});

        let charges      = {
            total:  0,
            groups: []
        }
        let currencyCode = props.hotelConfig.currencyCode;

        charges.groups = Object.values(reduced).map(function(chargeGroup, index) {

            charges.total += chargeGroup.price;

            let start = moment(chargeGroup.serviceDates[0]).format('DD.MM.YYYY');
            let end   = moment(chargeGroup.serviceDates[chargeGroup.serviceDates.length - 1]).format('DD.MM.YYYY');

            return <div key={index} className={'__charge'}>
                <span className={'__name'}>{chargeGroup.name}</span>
                <Translator className={'__count'} content={'services.countWithTime'} with={{
                    start: start,
                    end:   end,
                    count: chargeGroup.count
                }} />
                {(() => {
                    if (chargeGroup.price > 0 && folio.allowedPayment > 0) {
                        return <span className={'__price'}><Price currency={currencyCode} amount={chargeGroup.price} /></span>;
                    }
                })()}
            </div>;
        })

        return charges;
    }
    let totalPayments                      = 0;

    if (folio.payments !== null) {
        for (let i = 0; i < folio.payments.length; i++) {
            let payment = folio.payments[i];
            totalPayments += payment.amount.amount;
        }
    }
    if (folio.pending_payments !== null) {
        for (let i = 0; i < folio.pending_payments.length; i++) {
            let payment = folio.pending_payments[i];
            totalPayments += payment.amount.amount;
        }
    }

    /*TODO: neues feaure: timeslices aufteilen*/
    // const [modalIsOpen, toggleModal] = useState(false);
    // let groupTimeSlices = (serviceType) => {
    //     let timeSlices       = props.reservation.timeSlices;
    //     let groupedTimeSlices = {};
    //
    //     for(let i=0;i<timeSlices.length;i++){
    //
    //     }
    //
    //     return groupedTimeSlices;
    // }
    let currencyCode = props.hotelConfig.currencyCode;

    let total             = 0;
    let chargesBlocksHtml = [];

    let serviceTypesToDisplay = [
        APALEO_SERVICE_TYPE_FOODANDBEVERAGES,
        APALEO_SERVICE_TYPE_CANCELLATIONFEES,
        APALEO_SERVICE_TYPE_NOSHOW,
        APALEO_SERVICE_TYPE_OTHER
    ];

    for (let index = 0; index < serviceTypesToDisplay.length; index++) {
        let charges = groupCharges(serviceTypesToDisplay[index]);
        total += charges.total;
        if (charges.groups.length > 0) {
            chargesBlocksHtml.push(<div key={index} className="__charges">
                <p className="__title">
                    <Translator content={"service." + serviceTypesToDisplay[index]} />
                </p>
                {charges.groups}
            </div>)
        }
    }

    let accommodationHTML = null;
    if (typeof groupedServices.accommodation !== 'undefined' && groupedServices.accommodation.price > 0) {
        let serviceDates  = groupedServices.accommodation.serviceDates;
        let start         = moment(serviceDates[0]).format('DD.MM.YYYY');
        let end           = moment(serviceDates[serviceDates.length - 1]).format('DD.MM.YYYY');
        total += groupedServices.accommodation.price;
        accommodationHTML = <div className="__charges">
            <p className="__title" />
            <div className={'__charge'}>
                <Translator className={'__name'} content={'services.overnightStays'} />
                <Translator className={'__count'} content={'services.countWithTime'} with={{
                    start: start,
                    end:   end,
                    count: groupedServices.accommodation.count
                }} />
                <span className={'__price'}>
                            <Price currency={currencyCode} amount={groupedServices.accommodation.price} />
                        </span>
            </div>
        </div>;
    }
    let citytaxHTML = null;
    if (typeof groupedServices.citytax !== 'undefined' && groupedServices.citytax.price > 0) {
        let serviceDates1 = groupedServices.citytax.serviceDates;
        let start         = moment(serviceDates1[0]).format('DD.MM.YYYY');
        let end           = moment(serviceDates1[serviceDates1.length - 1]).format('DD.MM.YYYY');
        total += groupedServices.citytax.price;
        citytaxHTML       = <div className="__charges">
            <p className="__title" />
            <div className={'__charge'}>
                <Translator className={'__name'} content={'services.inclCitytax'} />
                <Translator className={'__count'} content={'services.countWithTime'} with={{
                    start: start,
                    end:   end,
                    count: groupedServices.citytax.count
                }} />
                <span className={'__price'}>
                            <Price currency={currencyCode} amount={groupedServices.citytax.price} />
                        </span>
            </div>
        </div>;
    }

    total -= totalPayments;

    let totalHtml = <div key={serviceTypesToDisplay.length} className="__total">
        <span><Translator content={'words.totalSum'} />:</span>
        <span><Price currency={currencyCode} amount={total} /></span>
    </div>;


    return <div className="service-details">
        {/*TODO: neues feaure: timeslices aufteilen*/}
        {/*<p className={'__headline'} onClick={() => {*/}
        {/*    toggleModal(!modalIsOpen)*/}
        {/*}}>*/}
        {/*    <Icon icon={modalIsOpen ? 'keyboard_arrow_left' : 'keyboard_arrow_right'} /><Translator*/}
        {/*    content={'services.yourAccomodation'} />*/}
        {/*</p>*/}
        {/*<Collapse isOpened={modalIsOpen}>*/}
        {/*  */}
        {/*</Collapse>*/}

        <p className={'__headline'}>
            <Translator content={'services.yourAccomodation'} />
        </p>
        {accommodationHTML}
        {citytaxHTML}
        <p className={'__headline'}>
            <Translator content={'services.yourExtras'} />
        </p>
        {chargesBlocksHtml}

        {(() => {
            if (totalPayments > 0) {
                return <div className="__charges">
                    <p className="__title" />
                    <div className={'__charge'}>
                        <Translator className={'__name'} content={'services.totalPayments'} />
                        <span className={'__count'} />
                        <span className={'__price'}>
                            <Price currency={currencyCode} amount={totalPayments * -1} />
                        </span>
                    </div>
                </div>;
            }
        })()}
        {totalHtml}
    </div>;
}

export default connect(state => {
    return {
        reservation: state.apaleo.reservation,
        locale:      state.app.locale,
        hotelConfig: state.app.hotelConfig
    }
}, {})(ServiceDetails);
