import React, {Component} from 'react';
import Header             from "../../components/Header";
import i18next            from "i18next";
import {goToMainPage}     from "../Types/Dispatcher";
import {PAGE_START}       from "../../constants/constants";

let counterpart = require('counterpart');

class Start extends Component
{
    changeLanguage = (lang) => {
        counterpart.setLocale(lang);
        i18next.changeLanguage(lang);
        goToMainPage(PAGE_START);
    }

    render()
    {
        return (<div className="page page-start">
            <Header/>
            <div className="app-content">
                <div className="absolute-wrapper">
                    <div className="welcome-wrap">
                        <p className={'__heading'}>Willkommen!</p>
                        <p>Bitte wählen Sie Ihre Sprache aus.</p>
                        <p className={'__heading'}>Welcome!</p>
                        <p>Please choose your language.</p>
                        <div className={'__buttons'}>
                            <button className={'btn btn-outline-secondary'}
                                    onClick={(e) => this.changeLanguage('de')} type={'button'}>
                                Deutsch
                            </button>
                            <button className={'btn btn-outline-secondary'}
                                    onClick={(e) => this.changeLanguage('en')} type={'button'}>
                                English
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}

export default Start;
