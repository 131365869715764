// @flow
import React, {Component}                from 'react';
import Header                            from "../../components/Header";
import type {GeneralProps, GeneralState} from "../Types/Types";
import * as Sentry                       from "@sentry/browser";
import PropTypes                         from "prop-types";

import {connect}      from "react-redux";
import {
    checkInNow,
    generateIdempotencyKey,
    onLoadReservationSuccess,
    showError,
    toggleSpinner
}                     from "../../redux/actions";
import {withRouter}   from "react-router";
import * as Constants from "../../constants/constants";
import {
    goToRoominformation,
    goToStartPage,
    goToThankyou
}                     from "../Types/Dispatcher";
import Translator     from "../../components/Translator";
import Price          from "../../components/Price";
import Footer         from "../../components/Footer";

type PaymentPageState = GeneralState & {
    invoiceAddress: number, breakfastService: number, paymentMsg: string,
}
type PaymentPageProps = GeneralProps & {}

class PaymentPage extends Component<PaymentPageProps, PaymentPageState>
{

    constructor(props: PaymentPageProps)
    {
        super(props);

        this.state = {
            invoiceAddress: {
                _index:     null,
                _valid:     false,
                firstName:  '',
                lastName:   '',
                company:    '',
                country:    '',
                city:       '',
                address:    '',
                postalCode: '',
                email:      ''
            }
        };
    }

    componentDidMount()
    {
        this.props.generateIdempotencyKey();

        if (typeof this.props.reservation !== 'undefined') {
            if (this.getPayableAmount() === 0) {
                this.beginCheckin();//TODO: config beachten?
            }
        }
    }

    getPayableAmount()
    {
        return this.props.reservation._meta?.folio?.allowedPayment ?? 0;
    }

    beginTransaction = () => {
        let PaymentPage = this;
        this.props.toggleSpinner(true, '');

        this.props.apiClient.startTransaction(
            this.props.reservation.id,
            this.props.idempotencyKey,
            this.state.invoiceAddress
        ).then(response => {
            this.props.toggleSpinner(false, '');
            if (typeof response.message !== 'undefined' && response.message) {
                switch (response.status) {
                    case 'success':
                        PaymentPage.beginCheckin();
                        break;
                    default:
                    case 'notFound':
                        Sentry.captureMessage('unknown response status when submiting cart.');
                        break;
                }
            } else if (typeof response.error !== 'undefined' && response.error) {
                switch (response.status) {
                    case 'payment-canceled':
                        this.props.showError('payment.errors.payment-canceled');
                        break;
                    case 'payment-pending':
                    case 'payment-unknown':
                    case 'payment-failure':
                    default:
                        this.props.showError('payment.errors.payment-general');
                        break;
                }
                PaymentPage.props.generateIdempotencyKey();
            } else {
                Sentry.captureMessage('unknown response for transaction.');
                this.props.showError('payment.errors.payment-general');
            }
        }).catch((err) => {
            this.props.showError("errors.unknown");
            PaymentPage.props.toggleSpinner(false, '');
            Sentry.captureException(err);
        });
    }

    beginCheckin = () => {
        let PaymentPage = this;
        this.props.toggleSpinner(true, '');
        this.props.checkInNow(Constants.PAGE_PAYMENT).then((response) => {
            PaymentPage.props.toggleSpinner(false, '');
            if (response.status === 'checkinSkipped') {
                goToThankyou(Constants.PAGE_PAYMENT);
            } else {
                goToRoominformation(Constants.PAGE_PAYMENT);
            }
        }).catch(function(err) {
            PaymentPage.props.toggleSpinner(false, '');
        });
    }

    render()
    {
        if (typeof this.props.reservation === 'undefined' || Object.entries(this.props.reservation).length === 0) {
            goToStartPage(Constants.PAGE_PAYMENT);
            return '';
        }
        let amount   = this.getPayableAmount();
        let currency = this.props.hotelConfig.currencyCode;

        return (<div className="page page-payment">
            <Header
                showSteps={3}
            />
            <div className="app-content">
                <div className={'container'}>
                    <div className={'page-header'}>
                        <Translator content={'payment.header'} />
                    </div>
                    {(() => {
                        if (amount > 0) {
                            return <div className="payment-container">
                                <div className="__text">
                                    <Translator content={'payment.text'} />
                                </div>
                                <div className="__missingamount">
                                    <Translator content={'payment.missingamount'} />:
                                </div>
                                <div className="__price">
                                    <Price currency={currency} amount={amount} />
                                </div>
                                <div className={'__btn-wrap'}>
                                    <button
                                        className="btn btn-primary btn-uppercase btn-fullwidth btn-space"
                                        onClick={() => {
                                            this.beginTransaction();
                                        }}>
                                        <Translator content={'button.payNow'} />
                                    </button>
                                </div>
                            </div>;
                        }
                    })()}
                </div>
            </div>
            <Footer />
        </div>);
    }
}

// Definition of LabeledInput component properties
PaymentPage.propTypes = {
    hotelInfos: PropTypes.object
};

// Definition of default LabeledInput component properties
PaymentPage.defaultProps = {
    hotelInfos: {}
};

export default withRouter(connect(state => {
    return {
        hotelConfig:            state.app.hotelConfig,
        apiClient:              state.app.apiClient,
        guestCredentials:       state.apaleo.guestCredentials,
        pagesConfig:            state.app.pagesConfig,
        primaryGuest:           state.apaleo.reservation.primaryGuest,
        reservation:            state.apaleo.reservation,
        signedGuests:           state.apaleo.signedGuests,
        reservationNeedsUpdate: state.apaleo.reservationNeedsUpdate,
        idempotencyKey:         state.app.idempotencyKey
    }
}, {
                                      onLoadReservationSuccess,
                                      checkInNow,
                                      toggleSpinner,
                                      showError,
                                      generateIdempotencyKey
                                  })(PaymentPage));
