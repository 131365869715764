import React                      from "react";
import {useDispatch, useSelector} from "react-redux";
import {removeServiceToCart}      from "../../redux/actions";
import ApaleoTranslator           from "../ApaleoTranslator";
import Price                      from "../Price";
import Translator                 from "../Translator";

const ExtrasCart = (props) => {
    const services      = useSelector((state) => state.cart.services);
    const serviceOffers = useSelector((state) => state.apaleo.serviceOffers);
    const hotelConfig   = useSelector((state) => state.app.hotelConfig);
    const dispatch      = useDispatch();
    let total           = 0;
    let currency        = hotelConfig.currencyCode;

    let cartItem       = [];
    let _serviceOffers = {};

    //TODO: get serviceOffers as "assoziative" array from server
    for (let i = 0; i < serviceOffers.length; i++) {
        let so                        = serviceOffers[i];
        _serviceOffers[so.service.id] = so;
    }
    let key = 0;
    for (let i = 0; i < services.length; i++) {
        let service      = services[i];
        let serviceOffer = _serviceOffers[service.id];
        if (typeof serviceOffer === 'undefined' || serviceOffer === null) {
            continue;
        }
        total += serviceOffer.totalAmount.grossAmount;

        cartItem.push(
            <div className={'service-box'} key={key++}>
                <div className={'__content'}>
                    <div className={'__image'}>
                        <img alt={'alt'} src={serviceOffer._meta.image} />
                    </div>
                    <div className={'__description'}>
                        <div className={'__title'}>
                            <ApaleoTranslator property={serviceOffer.service.name} />
                        </div>
                        <div className={'__text'}>
                            <ApaleoTranslator property={serviceOffer.service.description} length={120} />
                        </div>
                    </div>
                </div>


                <div className={'__single-price'}>
                    <Price currency={serviceOffer.dates[0].amount.currency}
                           amount={serviceOffer.dates[0].amount.grossAmount} />
                </div>


                <div className={'__total-price'}>
                    <Price currency={serviceOffer.totalAmount.currency}
                           amount={serviceOffer.totalAmount.grossAmount} />
                    <button
                        className={'btn btn-primary btn-slim'}
                        onClick={() => {
                            dispatch(removeServiceToCart(serviceOffer.service.id));
                        }}
                    >
                        <Translator content={'button.remove'} />
                    </button>
                </div>

            </div>
        );
    }

    return (
        <>
            <div className="modal-headline">
                <Translator content={'services.extrasCart'} with={{
                    count: services.length
                }} />
            </div>
            <div className="modal-content _cart">
                <div className={'cart'}>
                    <div className={'__top-bar'}>
                        <div className={'__first-col'}>
                            <Translator content={'words.item'} />
                        </div>
                        <div className={'__other-col'}>
                            <Translator content={'words.singlePrice'} />
                        </div>
                        <div className={'__other-col'}>
                            <Translator content={'words.totalPrice'} />
                        </div>
                    </div>
                    <div className={'__items'}>
                        {cartItem}
                    </div>
                    <div className={'__total'}>
                        <div>
                            <Translator content={'services.sumExtras'} />
                        </div>
                        <div>
                            <Price currency={currency} amount={total} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ExtrasCart;
