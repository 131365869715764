import React     from "react";
import Icon      from "../../Icon";
import {connect} from "react-redux";

const ReservationProperty = (props) => {
    let key              = 0;
    let header           = <div key={key++} className={'__header'}>
        {props.hotelConfig.name[props.locale]}
    </div>;
    let icon             = <Icon key={key++} icon={'MOOI_Icon_Pin'} />
    let __addressWrapper = <div key={key++} className={'__address-wrapper'}>
        <div>
            {props.hotelConfig.location.addressLine1}
        </div>
        <div>
            {props.hotelConfig.location.postalCode} {props.hotelConfig.location.city}
        </div>
    </div>;
    let html__header     = [];
    let html__address    = [];

    let propertyLayout = props.pagesConfig.reservationDetails.propertyLayout;
    switch (propertyLayout) {
        case 'ltr':
            html__address.push(icon);
            html__address.push(header)
            html__address.push(__addressWrapper);
            break;
        case 'ttb':
        default:
            html__header.push(header)
            html__address.push(icon);
            html__address.push(__addressWrapper);
            break;
    }

    return <div className={'__reservation-property _' + propertyLayout}>
        {html__header}
        <div className={'__address'}>
            {html__address}
        </div>
    </div>;
}

export default connect(state => {
    return {
        hotelConfig:       state.app.hotelConfig,
        meldescheinConfig: state.app.meldescheinConfig,
        reservation:       state.apaleo.reservation,
        pagesConfig:       state.app.pagesConfig,
        locale:            state.app.locale
    }
}, {})(ReservationProperty);
