import React      from "react";
import Translator from "../../Translator";

const KeycardControlsLayoutSimple = (props) => {
    return <div className="keycard-controls">
        <button
            onClick={props.onClick}
            className="btn btn-primary btn-uppercase btn-basewidth btn-space"
        >
            <Translator content={"checkin.buttons.getcard"}/>
        </button>
    </div>;
}


export default KeycardControlsLayoutSimple;
