// @flow
import React, {Component}                     from 'react';
import {connect}                              from "react-redux";
import {
    clearMessage,
    login,
    onLoadReservationError,
    onLoadReservationSuccess,
    showError,
    toggleSpinner,
    updateRoominformations
}                                             from "../../redux/actions";
import Translator                             from "../../components/Translator";
import Header                                 from "../../components/Header";
import BackButton                             from "../Buttons/BackButton";
import {withRouter}                           from "react-router";
import Footer                                 from "../../components/Footer";
import InputWithLabel                         from "../../components/InputWithLabel";
import Icon                                   from "../../components/Icon";
import counterpart                            from "counterpart";
import * as Constants                         from "../../constants/constants";
import {goToDetailsPage, goToRoominformation} from "../Types/Dispatcher";

class Login extends Component
{

    formLoginRequired = {
        bookingId: true,
        lastName:  true
    };

    constructor(props)
    {
        super(props);

        this.state = {
            errors:           {},
            errorMessage:     '',
            guestCredentials: {
                bookingId: process.env.REACT_APP_RESERVATION ?? '',
                lastName:  process.env.REACT_APP_LASTNAME ?? ''
            }
        };

        this.submitReservation = this.submitReservation.bind(this);
        this.handleSubmit      = this.handleSubmit.bind(this);
        this.handleChange      = this.handleChange.bind(this);
    }

    submitReservation()
    {
        let guestCredentials = this.state.guestCredentials;
        this.props.toggleSpinner(true, '');
        this.props.login(guestCredentials.bookingId, guestCredentials.lastName).then((response) => {
            if (response.status === 'Confirmed') {
                goToDetailsPage(Constants.PAGE_LOGIN);
            } else if (response.status === 'InHouse') {
                goToRoominformation(Constants.PAGE_LOGIN);
            }
        }).catch(function(response) {
            // error is already displayed in function
        });
    }

    handleSubmit(event)
    {
        event.preventDefault();
        event.stopPropagation();

        if (this.formIsValid()) {
            this.submitReservation()
        }
        return false;
    }

    handleChange(event)
    {
        let guestCredentials                = this.state.guestCredentials;
        guestCredentials[event.target.name] = event.target.value;
        this.handleBookingChanged(guestCredentials);
    }

    handleBookingChanged(changedBooking)
    {
        this.setState({guestCredentials: changedBooking});
    }

    formIsValid()
    {

        let isValid          = true;
        let guestCredentials = this.state.guestCredentials;
        let errors           = {};
        for (let field in this.formLoginRequired) {
            if (this.formLoginRequired.hasOwnProperty(field)) {
                let required = this.formLoginRequired[field];
                if (required && !(guestCredentials.hasOwnProperty(field) && guestCredentials[field])) {
                    isValid       = false;
                    errors[field] = counterpart.translate("forms.errors.required");
                    this.props.showError("messages.errors.required");
                }
            }
        }
        this.setState({errors: errors});

        return isValid;
    }

    render()
    {
        let enableSearch = (this.state.guestCredentials.bookingId.length < 1) ||
            (this.state.guestCredentials.lastName.length < 1);
        return (<div className="page page-login">
            <Header
                showSteps={1}
            />
            <div className="app-content">
                <div className="container login-container">
                    <div className={'page-header'}>
                        <Translator content={'main.header'}/>
                    </div>
                    <form id="login-form" className="terminal-form _login" onSubmit={this.handleSubmit}>
                        <div className="__form-container row">
                            <div className="col-6">
                                <div className={'__header'}><Translator content={'words.enterResId'}/></div>
                                <InputWithLabel
                                    className={'_uppercase'}
                                    value={this.state.guestCredentials.bookingId}
                                    label={'fields.bookingId'}
                                    updateInputValue={(value) => this.setState({
                                                                                   'guestCredentials': {
                                                                                       ...this.state.guestCredentials,
                                                                                       'bookingId': value
                                                                                   }
                                                                               })}
                                />
                            </div>
                            <div className={'col-6'}>
                                <div className={'__header'}><Translator content={'words.enterLastName'}/></div>
                                <InputWithLabel
                                    value={this.state.guestCredentials.lastName}
                                    label={'fields.lastName'}
                                    updateInputValue={(value) => this.setState({
                                                                                   'guestCredentials': {
                                                                                       ...this.state.guestCredentials,
                                                                                       'lastName': value
                                                                                   }
                                                                               })}
                                />
                            </div>
                        </div>
                    </form>
                </div>
                <div className="container">
                    <div className="button-row">
                        <BackButton/>
                        <button
                            form="login-form"
                            className="btn btn-primary btn-uppercase btn-fullwidth btn-space"
                            disabled={enableSearch}
                        >
                            <Translator content={"checkin.buttons.submit"}/>
                            <div className={'icon_wrapper'}>
                                <Icon color={enableSearch ? "#626262" : 'white'}
                                      icon="keyboard_arrow_right" size={30}/>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>);
    }
}

export default withRouter(connect(state => {
    return {
        pagesConfig: state.app.pagesConfig
    }
}, {
                                      onLoadReservationSuccess,
                                      onLoadReservationError,
                                      updateRoominformations,
                                      showError,
                                      clearMessage,
                                      toggleSpinner,
                                      login
                                  })(Login));

