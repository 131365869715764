import {globalAppState as initialState} from "../state";
import {SERVICES_ADD, SERVICES_REMOVE, EMPTY_CART} from "../../constants";

export const cartReducer = (state = initialState.cart, action) => {
    switch (action.type) {

        case EMPTY_CART:
            return initialState.cart

        case SERVICES_ADD:
            if (state.services.some(service => {
                return service.id === action.data.service.id;
            })) {
                return state;
            } else {
                return {
                    ...state,
                    services: [...state.services, action.data.service]
                };
            }
        case SERVICES_REMOVE:
            let filtered = state.services.filter(function (value, index, arr) {
                return value.id !== action.data.serviceId;
            });
            return {
                ...state,
                services: filtered
            };
        default:
            return state;
    }
};
