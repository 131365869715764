import {
    CHANGE_LOCALE,
    CLEAR_MESSAGE,
    DEFAULT_MESSAGE_TIMEOUT,
    EMPTY_CART,
    GENERATE_IDEMPOTENCY_KEY,
    ON_CHECKIN_ERROR,
    ON_LOAD_RESERVATION_ERROR,
    ON_LOAD_RESERVATION_SUCCESS,
    ON_UPDATE_SIGNED_GUESTS,
    RESERVATION_NEEDS_UPDATE,
    RESET_TERMINAL,
    SERVICES_ADD,
    SERVICES_REMOVE,
    SET_HOTEL_CONFIG,
    SHOW_MESSAGE,
    TOGGLE_SPINNER,
    UPDATE_ROOMINFORMATION
} from '../constants';

import * as Sentry           from "@sentry/browser";
import {goToPaymentPage}     from "../pages/Types/Dispatcher";
import {CHECKIN_STATE_READY} from "../constants/constants";

// @formatter:off
export const generateIdempotencyKey = () => {
    return { type: GENERATE_IDEMPOTENCY_KEY };
};
export const onLoadReservationSuccess = (data) => {
    return {type: ON_LOAD_RESERVATION_SUCCESS, data: data };
};
export const updateSignedGuests= (signedGuests) => {
    return {type: ON_UPDATE_SIGNED_GUESTS, signedGuests: signedGuests };
};
export const onLoadReservationError = (error) => {
    return {type: ON_LOAD_RESERVATION_ERROR, error : error };
};
export const reservationNeedsUpdate       = (reservationNeedsUpdate) => {
    return {type: RESERVATION_NEEDS_UPDATE, data: {reservationNeedsUpdate:reservationNeedsUpdate} };
};
export const updateRoominformations       = (data) => {
    return {type: UPDATE_ROOMINFORMATION, data: data };
};
export const onCheckinError         = (error) => {
    return {type: ON_CHECKIN_ERROR, error : error };
};

export const setHotelConfig = (config) => {
    return {type: SET_HOTEL_CONFIG, data: config}
};

export const changeLocale = (locale) => {
    return { type: CHANGE_LOCALE, data: {locale: locale} };
};
// services
export const addServiceToCart = (service) => {
    return { type: SERVICES_ADD, data: {service} };
};
export const removeServiceToCart = (serviceId) => {
    return { type: SERVICES_REMOVE, data: {serviceId} };
};
export const emptyCart = () => {
    return { type: EMPTY_CART, data: {} };
};
// terminal
export const resetTerminal = () => {
    return { type: RESET_TERMINAL };
};
export const showError = (message, timeout = DEFAULT_MESSAGE_TIMEOUT) => {
    return { type: SHOW_MESSAGE, data: {message: message, type: 'error', timeout: timeout} };
};
export const showWarning = (message, timeout = DEFAULT_MESSAGE_TIMEOUT) => {
    return { type: SHOW_MESSAGE, data: {message: message, type: 'warning', timeout: timeout} };
};
export const showTimeoutMessage = (timeout = DEFAULT_MESSAGE_TIMEOUT) => {
    return { type: SHOW_MESSAGE, data: {message: 'messages.timeout', type: 'timeout', timeout: timeout} };
};
export const showMessage = (message,type, timeout = DEFAULT_MESSAGE_TIMEOUT) => {
    return { type: SHOW_MESSAGE, data: {message: message, type: type, timeout: timeout} };
};
export const clearMessage = () => {
    return { type: CLEAR_MESSAGE };
};
export const toggleSpinner = (showLoader, loaderMessage) => {
    return { type: TOGGLE_SPINNER, data: {showLoader:showLoader, loaderMessage:loaderMessage} };
};
// @formatter:on


function handleGetReservationResponse(dispatch, response, bookingId, lastName, resolve, reject)
{
    const guestCredentials = {
        bookingId: bookingId,
        lastName:  lastName
    };
    dispatch(toggleSpinner(false, ''));

    if (response.reservation) {
        if (response.reservation && response.reservation.status === 'Confirmed') {
            // check checkin state: only
            if (response.reservation._meta.checkinState === 'ready') {
                dispatch(clearMessage());
                dispatch(onLoadReservationSuccess({
                                                      pathname:         'address',
                                                      guestCredentials: guestCredentials,
                                                      reservation:      response.reservation,
                                                      serviceOffers:    response.serviceOffers
                                                  }));
                resolve({status: response.reservation.status});
            } else {
                dispatch(showError("address.errors.occupied"));
                reject({status: 'error'});
            }
        } else if (response.reservation.status === 'InHouse') {
            // Bereits eingecheckt im Hotel. Hinweisen auf Lostcard
            // dispatch(showError("address.errors.msg2")); //TODO: show msg2 if "lostcard" is configure)d

            if (response.reservation._meta.keys_count === 0) {
                dispatch(clearMessage());
                dispatch(onLoadReservationSuccess({
                                                      pathname:         'roominformation',
                                                      guestCredentials: guestCredentials,
                                                      reservation:      response.reservation,
                                                      serviceOffers:    response.serviceOffers
                                                  }));
                dispatch(updateRoominformations({
                                                    roominformations: {
                                                        checkin: 'success',
                                                        name:    response.reservation.unit.name
                                                    }
                                                }));
                resolve({status: response.reservation.status});
            } else {
                dispatch(showError("address.errors.msg1"));
                reject({status: 'error'});
            }
        } else if (response.reservation.status === 'CheckedOut') {
            dispatch(showError("address.errors.outOfTime"));
            reject({status: 'error'});
        } else {
            dispatch(showError("address.errors.msg1"));
            reject({status: 'error'});
        }
    } else {
        switch (response.status) {
            case 'toManyFolios':
                // TODO: only if payment aktive?
                dispatch(showError("address.errors.terminalCanNot"));
                reject({status: 'error'});
                break;
            default:
                dispatch(showError("address.errors.msg1"));
                reject({status: 'error'});
                break;
        }
    }
}

export const checkInNow  = function(referrer = '') {
    return function(dispatch, getState) {
        return new Promise((resolve, reject) => {
            let state = getState();
            if (!state.app.pagesConfig.skipCheckin) {

                let option = 'all';

                if (state.app.meldescheinConfig.onlyForPrimaryGuest) {
                    option = 'primaryGuestOnly';
                }

                state.app.apiClient.checkIn(state.apaleo.reservation.id, option)
                     .then((response) => {
                         dispatch(toggleSpinner(false, ''));

                         if (undefined === response) {
                             reject('error');
                         }
                         if (response.error) {
                             let errorMsg = "address.errors.msg1";
                             if (typeof response.reason !== "undefined") {
                                 switch (response.reason) {
                                     case'occupied':
                                         errorMsg = "address.errors.occupied";
                                         break;
                                     case'registrationMissing':
                                         errorMsg = "address.errors.registrationMissing";
                                         break;
                                     case'future':
                                     case'past':
                                         errorMsg = "address.errors.outOfTime";
                                         break;
                                     case'unknown':
                                     default:
                                         errorMsg = "address.errors.msg1";
                                         break;
                                 }
                             }
                             dispatch(showError(errorMsg));
                             reject({status: response.reason});
                         } else {
                             let checkin = 'default';
                             // use response.checkin for backwards compatibility
                             if (response.checkinState === CHECKIN_STATE_READY
                                 || response.checkin === CHECKIN_STATE_READY) {
                                 checkin = 'success';
                             }
                             dispatch(updateRoominformations({
                                                                 roominformations: {
                                                                     checkin: checkin,
                                                                     name:    response.name
                                                                 }
                                                             }));
                             resolve({status: 'success'});
                         }
                     })
                     .catch((err) => {
                         Sentry.captureException(err);
                         dispatch(toggleSpinner(false, ''));
                         reject(err);
                     });
            } else {
                resolve({status: 'checkinSkipped'});
            }
        });
    };
};
export const addServices = function(bookingId, lastName, services) {
    return function(dispatch, getState) {
        return new Promise((resolve, reject) => {
            let state = getState();
            dispatch(toggleSpinner(true, ''));
            state.app.apiClient.addServices(bookingId, lastName, services).then((response) => {
                handleGetReservationResponse(dispatch, response, bookingId, lastName, resolve, reject);
            }).catch((err) => {
                // FIXME: unhandled Exception
                console.error(err);
                Sentry.captureException(err);
                dispatch(toggleSpinner(false, ''));
            });
        });
    };
};
export const login       = function(bookingId, lastName) {
    return function(dispatch, getState) {
        return new Promise((resolve, reject) => {
            let state = getState();
            dispatch(toggleSpinner(true, ''));
            state.app.apiClient.getReservation(bookingId, lastName).then((response) => {
                handleGetReservationResponse(dispatch, response, bookingId, lastName, resolve, reject);
            }).catch((err) => {
                // FIXME: unhandled Exception
                console.error(err);
                Sentry.captureException(err);
                dispatch(toggleSpinner(false, ''));
            });
        });
    };
};

// WIP:
export const updateReservationAndGoToPayment = function(referrer = '') {
    return function(dispatch, getState) {
        return new Promise((resolve, reject) => {
            let state = getState();
            dispatch(toggleSpinner(true, ''));
            state.app.apiClient.getReservation(state.apaleo.reservation.id, state.apaleo.reservation.booker.lastName)
                 .then((response) => {
                     dispatch(toggleSpinner(false, ''));
                     if (response.reservation) {
                         dispatch(onLoadReservationSuccess({
                                                               pathname:         'address',
                                                               guestCredentials: {
                                                                   bookingId: state.apaleo.reservation.id,
                                                                   lastName:  state.apaleo.reservation.booker.name
                                                               },
                                                               reservation:      response.reservation
                                                           }));
                         dispatch(clearMessage());
                         dispatch(reservationNeedsUpdate(false));
                         goToPaymentPage(referrer);
                     } else {
                         reject({status: response.status});
                     }
                 })
                 .catch((err) => {
                     Sentry.captureException(err);
                     dispatch(toggleSpinner(false, ''));
                     reject(err);
                 });
        });
    };
};

