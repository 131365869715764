import React       from "react";
import Translator  from "../Translator";
import Modal       from 'react-modal';
import Icon        from "../Icon";
import {connect}   from "react-redux";
import i18next     from "i18next";
import packageJson from "../../../package.json";

class SupportModal extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            modalIsOpen: {
                support: this.props.isModalOpen
            }
        };
    }

    render()
    {

        let faqs = [];
        let i    = 0;
        for (; ; i++) {
            if (!i18next.exists('faqs.' + i + '.title') || !i18next.exists('faqs.' + i + '.text')) {
                break;
            }
            faqs.push(<div key={i} className={'__entry'}>
                <Translator className={'__title'} content={'faqs.' + i + '.title'} />
                <Translator className={'__text'} content={'faqs.' + i + '.text'} />
            </div>);
        }
        faqs.push(<div key={++i} className={'__entry'}>
            <Translator className={'__title'} content={'components.modal.call'} />
            <span className={'__text'}>{this.props.specificallyCustomerInformation.supportNumber}</span>
        </div>);

        let length = faqs.length;
        let half   = Math.floor(length / 2);

        let faq1 = faqs.slice(0, half);
        let faq2 = faqs.slice(half, length);

        return (<Modal
            className={'text-modal'}
            isOpen={this.props.isModalOpen}
            onRequestClose={this.props.closeModal}
        >
            <div onClick={this.props.closeModal} className="modal-close">
                <Icon icon="MOOI_x" />
            </div>
            <div className="modal-headline">
                <Translator content={'supportModal.header'} />
            </div>
            <div className="modal-content">
                <div className={'faqs'}>
                    <div className={'row'}>
                        <div className={'col-6'}>{faq1}</div>
                        <div className={'col-6'}>{faq2}</div>
                        <div className={'col-12'}>
                            <div className="appversion">
                                Version: {packageJson.version}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>)
    }
}

export default (connect(state => {
    return {
        specificallyCustomerInformation: state.app.specificallyCustomerInformation
    }
})(SupportModal));
