import React, {useState} from "react";
import i18next           from "i18next";
import {textEllipsis}    from "./Functions";

const ApaleoTranslator        = (props) => {
    const [rerender, setRerender] = useState(false);
    i18next.on('languageChanged', function(lng) {
        setRerender(!rerender);
    })

    let TagName = props.component;
    let text    = props.property[i18next.language];

    if (props.length !== null && typeof props.length === 'number') {
        text = textEllipsis(text, props.length)
    }


    return (<TagName onClick={props.onClick} className={props.className}>{text ?? ''}</TagName>);
}
ApaleoTranslator.defaultProps = {
    property:  '',
    className: '',
    component: 'span',
    length:    null,
    onClick:   () => {
    }
};

export default ApaleoTranslator;
