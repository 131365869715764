import {history} from "../../redux/store";

// routing
export const goToAdressPage = (referer?: string) => {
    history.push({
                     pathname: '/main/checkin/address',
                     referer:  referer || ''
                 });
};

export const goToDetailsPage = (referer?: string) => {
    history.push({
                     pathname: '/main/checkin/details',
                     referer:  referer || ''
                 });
};

export const goToLoginPage = (referer?: string) => {
    history.push({
                     pathname: '/main/checkin/login',
                     referer:  referer || ''
                 });
};

export const goToMainPage = (referer?: string) => {
    history.push({
                     pathname: '/main',
                     referer:  referer || ''
                 });
};

export const goToPaymentPage = (referer?: string) => {
    history.push({
                     pathname: '/main/checkin/payment',
                     referer:  referer || ''
                 });
};

export const goToStartPage = (referer?: string) => {
    history.push({
                     pathname: process.env.PUBLIC_URL + '/',
                     referer:  referer || ''
                 });
};

export const goToLostcardPage = (referer?: string) => {
    history.push({
                     pathname: '/main/lostcard',
                     referer:  referer || ''
                 });
};

export const goToSignature = (referer?: string) => {
    history.push({
                     pathname: '/main/checkin/signature',
                     referer:  referer || ''
                 });
};

export const goToCitytax = (referer?: string) => {
    history.push({
                     pathname: '/main/checkin/citytax',
                     referer:  referer || ''
                 });
};

export const goToRoominformation = (referer?: string) => {
    history.push({
                     pathname: '/main/checkin/roominformation',
                     referer:  referer || ''
                 });
};

export const goToThankyou = (referer?: string) => {
    history.push({
                     pathname: '/main/thankyou',
                     referer:  referer || ''
                 });
};

