import React, {Component} from "react";
import Translator         from "./Translator";
import PropTypes          from "prop-types";
import {resetTerminal}    from "../redux/actions";
import {goToMainPage}     from "../pages/Types/Dispatcher";
import Icon               from "./Icon";
import SupportModal       from "./Modal/SupportModal";
import {t}                from "i18next";
import {connect}          from "react-redux";

class Footer extends Component
{

    constructor(props)
    {
        super(props);
        this.state      = {
            modalIsOpen: false
        };
        this.openModal  = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    cancelCheckin()
    {
        this.props.resetTerminal();
        goToMainPage();
    }

    openModal()
    {
        this.setState({modalIsOpen: true});
    }

    closeModal()
    {
        this.setState({modalIsOpen: false});
    }

    render()
    {
        let label = null;
        if (t('components.footer.support')) {
            label = <div className={'__label'}>
                <Translator content={'components.footer.support'}/>
            </div>;
        }
        return (<div className={'app-footer'}>
            <div className={'container'}>
                <div className={'cancel ' + (!this.props.showCancel ? 'd-none' : '')}
                     onClick={() => this.cancelCheckin()}>
                    <Translator content={'button.cancel'}/>
                </div>
                <div className={'spacer'}/>
                <div className={'help'} onClick={() => {
                    this.openModal()
                }}>
                    <div className={'__icon'}>
                        <Icon icon={'MOOI_Information'}/>
                    </div>
                    {label}
                </div>
                <SupportModal isModalOpen={this.state.modalIsOpen} closeModal={this.closeModal}/>
            </div>
        </div>);
    }
}

// Definition of LabeledInput component properties
Footer.propTypes = {
    showCancel: PropTypes.bool
};

// Definition of default LabeledInput component properties
Footer.defaultProps = {
    showCancel: true
};


export default connect(state => {
    return {}
}, {
                           resetTerminal
                       })(Footer);
