import {globalAppState as initialState} from "../state";
import {
    ON_CHECKIN_ERROR,
    ON_LOAD_RESERVATION_ERROR,
    ON_LOAD_RESERVATION_SUCCESS,
    ON_UPDATE_SIGNED_GUESTS,
    RESERVATION_NEEDS_UPDATE,
    RESET_TERMINAL,
    UPDATE_ROOMINFORMATION
}                                       from "../../constants";

export const apaleoReducer = (state = initialState.apaleo, action) => {
    switch (action.type) {
        case RESERVATION_NEEDS_UPDATE:
            return {
                ...state,
                reservationNeedsUpdate: action.data.reservationNeedsUpdate
            };
        case ON_LOAD_RESERVATION_SUCCESS:
            return {
                ...state,
                guestCredentials: action.data.guestCredentials,
                reservation:      action.data.reservation,
                serviceOffers:    action.data.serviceOffers
            };
        case ON_LOAD_RESERVATION_ERROR:
            return {
                ...state
            };
        case UPDATE_ROOMINFORMATION:
            return {
                ...state,
                roominformations: action.data.roominformations
            };
        case ON_CHECKIN_ERROR:
            return {
                ...state
            };
        case ON_UPDATE_SIGNED_GUESTS:
            return {
                ...state,
                signedGuests: action.signedGuests
            };

        case RESET_TERMINAL:
            return {
                ...initialState.apaleo
            };

        default:
            return state;
    }
};
