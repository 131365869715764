import React, {Component}         from "react";
import Translator                 from "../Translator";
import CountrySelect              from "../CountrySelect";
import * as Sentry                from "@sentry/browser";
import {withRouter}               from "react-router";
import {connect}                  from "react-redux";
import {showError, toggleSpinner} from "../../redux/actions";
import SignaturePad               from "react-signature-pad-wrapper";
import InputWithLabel             from "../InputWithLabel";
import Icon                       from "../Icon";

import langEn               from "../../languages/en";
import langDe               from "../../languages/de";
import BackButton           from "../../pages/Buttons/BackButton";
import MeldescheinHelpModal from "../Modal/MeldescheinHelpModal";
import moment               from "moment";
import TextModal            from "../Modal/TextModal";
import ManualDateInput      from "../ManualDateInput";

let counterpart = require('counterpart');
counterpart.registerTranslations('en', langEn);
counterpart.registerTranslations('de', langDe);

const LAST_PAGE  = 2;
const FIRST_PAGE = 1;

class RegistrationForm extends Component
{
    _isMounted = false;

    formAddressRequired = {
        lastName:    true,
        firstName:   true,
        birthDate:   true,
        address:     true,
        postalCode:  true,
        city:        true,
        country:     true,
        nationality: true,
        signature:   true
    };

    constructor(props)
    {
        super(props);

        let guest       = this.determineGuest(this.props);
        let address     = this.getBookerAddress(guest);
        let reservation = this.props.reservation;

        this.state = {
            errors:           {},
            errorMessage:     '',
            showErrorMessage: false,
            page:             FIRST_PAGE,
            startSigning:     false,
            editing:          !this.props.meldescheinConfig.displayEditDataButton,
            additionalGuests: (!!reservation.additionalGuests && reservation.additionalGuests.length > 0) || false,
            helpModal:        false,
            signedGuest:      {
                lastName:             guest.lastName || '',
                firstName:            guest.firstName || '',
                birthDate:            guest.birthDate || '',
                identificationNumber: guest.identificationNumber || '',
                nationality:          guest.nationalityCountryCode || '',
                comment_firstName:    (!!reservation.additionalGuests[0] &&
                    reservation.additionalGuests[0].firstName) || '',
                comment_lastName:     (!!reservation.additionalGuests[0] && reservation.additionalGuests[0].lastName) ||
                                          '',
                address:              address.address || '',
                postalCode:           address.postalCode || '',
                city:                 address.city || '',
                country:              address.country || '',
                _index:               guest._index || '',
                signature:            '',
                agb:                  false,
                privacy:              false
            },
            agbModal:         false,
            privacyModal:     false
        };

        this.signatureClear = this.signatureClear.bind(this);
        this.startSigning   = this.startSigning.bind(this);
        this.handleChange   = this.handleChange.bind(this);
        this.handleChange   = this.handleChange.bind(this);
        this.handleSubmit   = this.handleSubmit.bind(this);
    }

    componentWillReceiveProps(nextProps, nextContext)
    {
        let message = 'do nothing';

        if (this.props.guestIndex !== nextProps.guestIndex) {
            let guest   = this.determineGuest(nextProps);
            let address = this.getBookerAddress(guest);
            message     = 'reset signedGuest: ' + this.props.guestIndex + ' !==' + nextProps.guestIndex;
            this.setState({
                              signedGuest: {
                                  ...this.state.signedGuest,
                                  lastName:             guest.lastName || '',
                                  firstName:            guest.firstName || '',
                                  birthDate:            guest.birthDate || '',
                                  identificationNumber: guest.identificationNumber || '',
                                  nationality:          guest.nationalityCountryCode || '',
                                  comment_firstName:    '',
                                  comment_lastName:     '',
                                  address:              address.address || '',
                                  postalCode:           address.postalCode || '',
                                  city:                 address.city || '',
                                  country:              address.country || '',
                                  _index:               guest._index || '',
                                  signature:            '',
                                  agb:                  false,
                                  privacy:              false
                              }
                          });
        }

        Sentry.addBreadcrumb({
                                 category: "RegistrationForm: componentWillReceiveProps",
                                 message:  message,
                                 level:    'log'
                             });
    }

    componentDidUpdate(prevProps, prevState, snapshot)
    {
        if (prevState.editing !== this.state.editing) {
            this.firstInputRef.handleFocus();
        }

        const guest = {};

        const anonymizeString = (value) => {
            let returnValue = 'empty';

            const oldState = prevState.signedGuest[value];
            const newState = this.state.signedGuest[value];

            if (newState !== '') {
                returnValue = 'filled';
            }
            if (newState !== oldState) {
                returnValue += ':changed';
            }

            return returnValue;
        }

        guest.lastName             = anonymizeString('lastName');
        guest.firstName            = anonymizeString('firstName');
        guest.birthDate            = anonymizeString('birthDate');
        guest.identificationNumber = anonymizeString('identificationNumber');
        guest.nationality          = anonymizeString('nationality');
        guest.comment_firstName    = anonymizeString('comment_firstName');
        guest.comment_lastName     = anonymizeString('comment_lastName');
        guest.address              = anonymizeString('address');
        guest.postalCode           = anonymizeString('postalCode');
        guest.city                 = anonymizeString('city');
        guest.country              = anonymizeString('country');
        guest.signature            = anonymizeString('signature');
        guest.agb                  = this.state.signedGuest.agb.toString();
        guest.privacy              = this.state.signedGuest.privacy.toString();

        Sentry.addBreadcrumb({
                                 category: "RegistrationForm:componentDidUpdate",
                                 message:  JSON.stringify(guest),
                                 level:    'Debug'
                             });
    }

    componentDidMount()
    {
        this._isMounted = true;
    }

    componentWillUnmount()
    {
        this._isMounted = false;
    }

    startSigning()
    {
        this.setState({startSigning: true});
    }

    signatureClear(event)
    {
        event.preventDefault();
        if (this.signaturePad) {
            this.signaturePad.clear();
            this.setState({startSigning: false});
        }
    }

    getBookerAddress(booker)
    {
        let address = {
            address:    "",
            postalCode: "",
            city:       "",
            country:    ""
        };

        if (booker.address) {
            address.address    = booker.address.addressLine1;
            address.postalCode = booker.address.postalCode;
            address.city       = booker.address.city;
            address.country    = booker.address.countryCode;
        }

        return address;
    }

    async handleSubmit()
    {
        // TODO: signature schon bei change füllen
        let signature = '';
        if (this.signaturePad && !this.signaturePad.isEmpty()) {
            signature = this.signaturePad.toDataURL("image/svg+xml");
        }

        let signedGuest = {
            ...this.state.signedGuest,
            signature: signature
        };


        let errors     = this.validateForm(signedGuest, LAST_PAGE);
        let errorsKeys = Object.keys(errors);
        if (errorsKeys.length === 0 && this.state.page === LAST_PAGE) {
            this.setState({errors: errors}, () => {
                this.props.toggleSpinner(true, '');
                this.props.apiClient.updateGuest(
                    this.props.reservation.id,
                    this.props.booker.lastName,
                    this.props.guestIndex,
                    signedGuest
                )
                    .then((response) => {
                        if (response.message === 'success') {
                            this.props.onGuestSend(signedGuest).then(() => {
                                if (this._isMounted) {
                                    this.setState({page: FIRST_PAGE});
                                }
                            });
                        } else {
                            //TODO: errorhandling?
                            this.props.toggleSpinner(false, '');
                        }
                    })
                    .catch((err) => {
                        // FIXME: unhandled Exception
                        Sentry.captureException(err);
                        this.props.toggleSpinner(false, '');
                    });
            });
        } else if (this.state.page === LAST_PAGE) {
            this.props.showError(errors[errorsKeys[0]]);
            this.setState({errors: errors});
        }

        return false;
    }

    isFieldRequired(field)
    {
        let required = false;
        if (this.formAddressRequired.hasOwnProperty(field)) {
            required = this.formAddressRequired[field];
        }

        return required;
    }

    handleChange(event)
    {
        this.setState({
                          signedGuest: {
                              ...this.state.signedGuest,
                              [event.target.name]: event.target.value
                          }
                      });
    }

    validateForm(signedGuest, page)
    {
        let lastPageFields = ['signature', 'agb', 'privacy'];

        let errors = {};
        for (let field in this.formAddressRequired) {
            let required = this.formAddressRequired[field];
            if (!lastPageFields.includes(field) && required &&
                !(signedGuest.hasOwnProperty(field) && signedGuest[field])) {
                errors[field] = counterpart('messages.errors.required');
            }
        }

        if (!this.props.meldescheinConfig.identNumNotRequiredArray.includes(signedGuest.nationality)) {
            if (!(signedGuest.hasOwnProperty('identificationNumber') && signedGuest.identificationNumber)) {
                errors.identificationNumber = counterpart('messages.errors.required');
            }
        }

        if (page === LAST_PAGE) {
            if (signedGuest.signature === '') {
                errors['signature'] = counterpart("messages.errors.required");
            }
            if (!signedGuest.agb) {
                errors['agb'] = counterpart("messages.errors.required");
            }
            if (!signedGuest.privacy) {
                errors['privacy'] = counterpart("messages.errors.required");
            }
        }

        let noErrors = Object.keys(errors).length === 0;
        if (!noErrors && page === LAST_PAGE) {
            let id = 'No Reservation-ID';
            if (typeof this.props.reservation !== 'undefined' && this.props.reservation !== null) {
                id = this.props.reservation.id;
            }
            let message = '[' + id + '] Errors on LAST_PAGE: ' + JSON.stringify(errors);
            Sentry.captureMessage(message);
        }
        return errors;
    }

    render()
    {
        const readOnly    = !this.state.editing;
        const reservation = this.props.reservation;
        let inputclass    = !this.state.additionalGuests ? "input-normal" : "input-extended"

        // TODO: trans-keys anpassen
        let pageHeaderKey    = 'address.meldeschein';
        let subpageHeaderKey = '';
        if (this.state.editing) {
            pageHeaderKey    = 'address.editmeldeschein';
            subpageHeaderKey = 'address.subheader';
        }

        if (this.state.page === 2) {
            pageHeaderKey    = 'address.signatureheader';
            subpageHeaderKey = 'signature.description';
        }


        let country     = this.state.signedGuest.country;
        let nationality = this.state.signedGuest.nationality;

        let requiredIdentificationNumber = !this.props.meldescheinConfig.identNumNotRequiredArray.includes(nationality);
        let readOnlyIdentificationNumber = !requiredIdentificationNumber;

        if (this.props.meldescheinConfig.identNumNotRequiredArray.includes(nationality)) {
            requiredIdentificationNumber = false;
            readOnlyIdentificationNumber = true;
        }

        return (<>
            <div className="container">
                <div className="page-header">
                    <Translator content={pageHeaderKey} />
                </div>
                <div className="page-subheader">
                    <Translator content={subpageHeaderKey} />
                </div>
                <p>{this.props.locale}</p>
                <div className={'registration-form'}>
                    {(() => {
                        switch (this.state.page) {
                            case FIRST_PAGE:
                                return <div className="meldeschein-form">
                                    <div className={'__guest _main'}>
                                        <Translator content={'address.guest'} />&nbsp;1
                                    </div>
                                    <div className={"row " + inputclass}>
                                        <div className="form-group col-6">
                                            <InputWithLabel
                                                isRequired
                                                ref={ref => this.firstInputRef = ref}
                                                readOnly={readOnly}
                                                value={this.state.signedGuest.firstName}
                                                label={'fields.firstName'}
                                                updateInputValue={(firstName) => this.setState({
                                                                                                   signedGuest: {
                                                                                                       ...this.state.signedGuest,
                                                                                                       firstName: firstName
                                                                                                   }
                                                                                               })}
                                            />
                                        </div>
                                        <div className="form-group col-6">
                                            <InputWithLabel
                                                isRequired
                                                readOnly={readOnly}
                                                value={this.state.signedGuest.lastName}
                                                label={'fields.lastName'}
                                                updateInputValue={(lastName) => this.setState({
                                                                                                  signedGuest: {
                                                                                                      ...this.state.signedGuest,
                                                                                                      lastName: lastName
                                                                                                  }
                                                                                              })}
                                            />
                                        </div>
                                    </div>
                                    <div className={"row " + inputclass}>
                                        <div className="form-group col-6">
                                            <InputWithLabel
                                                isRequired
                                                readOnly={readOnly}
                                                value={this.state.signedGuest.address}
                                                label={'fields.address'}
                                                updateInputValue={(address) => this.setState({
                                                                                                 signedGuest: {
                                                                                                     ...this.state.signedGuest,
                                                                                                     address: address
                                                                                                 }
                                                                                             })}
                                            />
                                        </div>

                                        <div className={'col-6'}>
                                            <div className={'row'}>
                                                <div className="form-group col-7">
                                                    <InputWithLabel
                                                        isRequired
                                                        readOnly={readOnly}
                                                        value={this.state.signedGuest.city}
                                                        label={'fields.city'}
                                                        updateInputValue={(city) => this.setState({
                                                                                                      signedGuest: {
                                                                                                          ...this.state.signedGuest,
                                                                                                          city: city
                                                                                                      }
                                                                                                  })}
                                                    />
                                                </div>
                                                <div className="form-group col-4 offset-1">
                                                    <InputWithLabel
                                                        isRequired
                                                        readOnly={readOnly}
                                                        value={this.state.signedGuest.postalCode}
                                                        label={'fields.postalCode'}
                                                        updateInputValue={(postalCode) => this.setState({
                                                                                                            signedGuest: {
                                                                                                                ...this.state.signedGuest,
                                                                                                                postalCode: postalCode
                                                                                                            }
                                                                                                        })}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"row " + inputclass}>
                                        <div className="form-group col-6">
                                            <CountrySelect
                                                isRequired
                                                readOnly={readOnly}
                                                label="fields.country"
                                                required={true}
                                                name="country"
                                                value={country}
                                                onChange={this.handleChange}
                                                errors={this.state.errors.country}
                                            />
                                        </div>
                                        <div className="form-group col-6">
                                            <CountrySelect
                                                isRequired
                                                readOnly={readOnly}
                                                label="fields.nationality"
                                                required={true}
                                                name="nationality"
                                                value={nationality}
                                                onChange={this.handleChange}
                                                errors={this.state.errors.nationality}
                                            />
                                        </div>
                                    </div>
                                    <div className={"row " + inputclass}>
                                        <div className="form-group col-6">
                                            <ManualDateInput
                                                id={'fields.birthDate'}
                                                label={'fields.birthDate'}
                                                value={this.state.signedGuest.birthDate}
                                                name="birthDate"
                                                required={true}
                                                onChange={this.handleChange}
                                                maxDate={moment().format('y-m-d')}
                                                error={typeof this.state.errors['birthDate'] !== 'undefined'}
                                            />
                                        </div>
                                        <div className={'form-group col-6 '}>
                                            <InputWithLabel
                                                value={this.state.signedGuest.identificationNumber}
                                                label={'fields.identificationNumber'}
                                                isRequired={requiredIdentificationNumber}
                                                required={requiredIdentificationNumber}
                                                readOnly={readOnlyIdentificationNumber}
                                                updateInputValue={(identificationNumber) => this.setState({
                                                                                                              signedGuest: {
                                                                                                                  ...this.state.signedGuest,
                                                                                                                  identificationNumber: identificationNumber
                                                                                                              }
                                                                                                          })}
                                            />
                                        </div>
                                    </div>

                                    {(() => {
                                        let displayCommentFields;
                                        switch (this.props.meldescheinConfig.displayCommentFields) {
                                            case 'never':
                                                displayCommentFields = false;
                                                break;
                                            case 'onGuests':
                                                displayCommentFields = reservation.adults > 1;
                                                break;
                                            case 'always':
                                            default:
                                                displayCommentFields = true;
                                                break;
                                        }

                                        if (displayCommentFields) {
                                            return <div>
                                                <div className={'__guest _second'}>
                                                    <Translator
                                                        content={(this.state.additionalGuests ? 'address.guest'
                                                                                              : 'address.add_guest')} />&nbsp;{this.state.additionalGuests &&
                                                    (<>2</>)}
                                                    {!this.state.additionalGuests && (<div onClick={() => {
                                                        this.setState({additionalGuests: 1})
                                                    }}>
                                                        <Icon icon={'MOOI_plus'} />
                                                    </div>)}
                                                </div>
                                                {this.state.additionalGuests && (<div className={'row animated'}>
                                                    <div className={'col-6'}>
                                                        <InputWithLabel
                                                            readOnly={readOnly && !!reservation.additionalGuests}
                                                            value={this.state.signedGuest.comment_firstName}
                                                            label={'fields.firstName'}
                                                            updateInputValue={(comment_firstName) => this.setState({
                                                                                                                       signedGuest: {
                                                                                                                           ...this.state.signedGuest,
                                                                                                                           comment_firstName: comment_firstName
                                                                                                                       }
                                                                                                                   })}
                                                        />
                                                    </div>
                                                    <div className={'col-6'}>
                                                        <InputWithLabel
                                                            readOnly={readOnly && !!reservation.additionalGuests}
                                                            value={this.state.signedGuest.comment_lastName}
                                                            label={'fields.lastName'}
                                                            updateInputValue={(comment_lastName) => this.setState({
                                                                                                                      signedGuest: {
                                                                                                                          ...this.state.signedGuest,
                                                                                                                          comment_lastName: comment_lastName
                                                                                                                      }
                                                                                                                  })}
                                                        />
                                                    </div>
                                                </div>)}
                                            </div>;
                                        }
                                    })()}
                                </div>;
                            case LAST_PAGE:
                                let showAGBhtml             = null;
                                let showSignatureHeaderhtml = null;
                                if (this.props.meldescheinConfig.showAGB) {
                                    showAGBhtml = <div className={'agb-wrap'}>
                                        <div className={"custom-control custom-checkbox" +
                                            (this.state.errors.hasOwnProperty('agb') ? ' is-invalid' : '')}>
                                            <input type="checkbox" className="custom-control-input" id="agbCheck"
                                                   required={true}
                                                   checked={this.state.signedGuest.agb}
                                                   onChange={(e) => {
                                                       this.setState({
                                                                         signedGuest: {
                                                                             ...this.state.signedGuest,
                                                                             agb: !this.state.signedGuest.agb
                                                                         }
                                                                     })
                                                   }}
                                            />
                                            <label className="custom-control-label" htmlFor="agbCheck">
                                                <Translator content={'signature.checkbox.agb'} components={{
                                                    ctaButton: <button onClick={() => {
                                                        this.setState({agbModal: true})
                                                    }} className="btn-inline-link" type={'button'} />
                                                }} />
                                            </label>
                                            <TextModal
                                                isModalOpen={this.state.agbModal}
                                                closeModal={() => {
                                                    this.setState({agbModal: false})
                                                }}
                                                headline="modal.agb.header"
                                                text={'agb'}
                                            />
                                        </div>
                                        <div className={"custom-control custom-checkbox" +
                                            (this.state.errors.hasOwnProperty('privacy') ? ' is-invalid' : '')}>
                                            <input type="checkbox" className="custom-control-input" id="privacyCheck"
                                                   required={true}
                                                   checked={this.state.signedGuest.privacy}
                                                   onChange={(e) => {
                                                       this.setState({
                                                                         signedGuest: {
                                                                             ...this.state.signedGuest,
                                                                             privacy: !this.state.signedGuest.privacy
                                                                         }
                                                                     })
                                                   }}
                                            />
                                            <label className="custom-control-label" htmlFor="privacyCheck">
                                                <Translator content={'signature.checkbox.privacy'} components={{
                                                    ctaButton: <button onClick={() => {
                                                        this.setState({privacyModal: true})
                                                    }} className="btn-inline-link" type={'button'} />
                                                }} />
                                            </label>
                                            <TextModal
                                                isModalOpen={this.state.privacyModal}
                                                closeModal={() => {
                                                    this.setState({privacyModal: false})
                                                }}
                                                headline="modal.privacy.header"
                                                text={'privacy'}
                                            />
                                        </div>
                                    </div>;
                                }
                                if (this.props.meldescheinConfig.showSignatureHeader) {
                                    showSignatureHeaderhtml = <div className={'signature-header'}>
                                        <div className={'__header'}>
                                            <Translator content={'fields.signature'} />
                                        </div>
                                        <div className={'__description'}>
                                            <Translator content={'signature.description'} />
                                        </div>
                                    </div>;
                                }

                                return <div>
                                    {showAGBhtml}
                                    {showSignatureHeaderhtml}
                                    <div className="signature-row">
                                        <div className="form-group">
                                            <div
                                                className={"signature-pad " +
                                                    (this.state.errors.hasOwnProperty('signature') ? 'is-invalid' : '')}
                                            >
                                                <Icon icon={'touch_app'} size={120} color={'#B5B5B5'}
                                                      className={'touchpad-icon' +
                                                          (this.state.startSigning ? ' d-none' : ' ')} />
                                                <SignaturePad ref={ref => this.signaturePad = ref}
                                                              options={{onBegin: this.startSigning}} />
                                                <button style={{"height": "100%"}}
                                                        onClick={this.signatureClear}>
                                                    X
                                                </button>
                                            </div>
                                        </div>
                                        <div className="bodytext-app">
                                            <Translator content="signature.information" />
                                        </div>
                                    </div>
                                </div>;
                            default:
                                return null;
                        }
                    })()}
                </div>
            </div>
            <div className="container">
                <div className="button-row">
                    {this.props.meldescheinConfig.displayEditDataButton && this.state.page === FIRST_PAGE ? <div
                        onClick={() => this.setState({editing: !this.state.editing})}
                        className="btn btn-secondary btn-uppercase btn-space"
                    >
                        {this.state.editing ? <Translator content="button.saveInformation" /> : <Translator
                            content="button.editInformation" />}
                    </div> : null}
                    {/*Button: Back Button*/}
                    {this.state.page === FIRST_PAGE && (<BackButton />)}
                    {this.state.page === LAST_PAGE && (<button
                        onClick={(e) => {
                            this.signatureClear(e);
                            this.setState({page: FIRST_PAGE})
                        }}
                        className="back-button btn btn-secondary btn-uppercase btn-space"
                    >
                        <Translator content={'start.goback'} />
                    </button>)}
                    {/*Button: HelpModal*/}
                    {this.state.page === 1 && (<button className={'btn-meldeschein-help'} onClick={() => {
                        this.setState({helpModal: true})
                    }}>
                        <Icon icon={'MOOI_Fragezeichen'} />
                    </button>)}
                    <MeldescheinHelpModal isModalOpen={this.state.helpModal} closeModal={() => {
                        this.setState({helpModal: false})
                    }} />
                    {/*Button: Daten bestätigen*/}
                    <button
                        onClick={() => {
                            if (this.state.page === FIRST_PAGE) {
                                let errors     = this.validateForm(this.state.signedGuest, FIRST_PAGE);
                                let errorsKeys = Object.keys(errors);
                                if (errorsKeys.length === 0) {
                                    this.setState({errors: errors, page: LAST_PAGE})
                                } else {
                                    this.props.showError(errors[errorsKeys[0]]);
                                    this.setState({errors: errors});
                                }
                            } else {
                                this.handleSubmit();
                            }
                        }}
                        className="btn btn-primary btn-uppercase btn-fullwidth btn-space"
                    >
                        <Translator content="button.confirmInformation" />
                    </button>
                </div>
            </div>
        </>);
    }

    determineGuest(props)
    {
        let guest = undefined;
        if (props.guestIndex === -1) {
            guest = props.reservation.primaryGuest;
        } else {
            guest = props.reservation.additionalGuests[props.guestIndex];
        }
        guest._index = props.guestIndex;
        return guest;
    }
}

export default withRouter(connect(state => {
    return {
        apiClient:         state.app.apiClient,
        pagesConfig:       state.app.pagesConfig,
        hotelConfig:       state.app.hotelConfig,
        meldescheinConfig: state.app.meldescheinConfig,
        booker:            state.apaleo.reservation.booker,
        reservation:       state.apaleo.reservation
    }
}, {
                                      toggleSpinner,
                                      showError
                                  })(RegistrationForm));
