import React, {useState} from "react";
import Translator        from "../Translator";
import Modal             from 'react-modal';
import Icon              from "../Icon";
import {useSelector}     from "react-redux";
import ReactHtmlParser   from "html-react-parser";
import i18next           from "i18next";

const AddExtrasModal = (props) => {
    const hotelConfig             = useSelector((state) => state.app.hotelConfig);
    const [rerender, setRerender] = useState(false);
    i18next.on('languageChanged', function(lng) {
        setRerender(!rerender);
    })

    let rawText = '';
    if (typeof hotelConfig._meta.texts[props.text] === 'object') {
        rawText = hotelConfig._meta.texts[props.text][i18next.language];
    }


    function getSize(element)
    {
        if (typeof element === 'object' && Array.isArray(element.props.children)) {
            let elementSize = 0;
            for (let i = 0; i < element.props.children.length; i++) {
                let child = element.props.children[i];
                elementSize += getSize(child);
            }

            return elementSize;
        } else if (typeof element === 'string') {
            return element.length;
        } else {
            return 1;
        }
    }

    let textLength = 0;
    let text       = ReactHtmlParser(rawText).filter((element, index) => {
        if (typeof element === 'object') {
            textLength += getSize(element);
        }

        return typeof element === 'object';
    });

    let textLenghtLeft = 0;
    let leftText       = [];
    let rightText      = [];

    for (let i = 0; i < text.length; i++) {
        let element = text[i];
        if (textLenghtLeft <= (textLength / 2)) {
            leftText.push(element);
            textLenghtLeft += getSize(element);
        } else {
            rightText.push(element);
        }
    }

    return <Modal
        className={'text-modal'}
        isOpen={props.isModalOpen}
        onRequestClose={props.closeModal}
    >
        <div onClick={props.closeModal} className="modal-close">
            <Icon icon="MOOI_x" />
        </div>
        <div className="modal-headline">
            <Translator content={props.headline} />
        </div>
        <div className="modal-content">
            <div className={'bodytext-app'}>
                <div className={'row'}>
                    <div className={'col-6'}>
                        {leftText}
                    </div>
                    <div className={'col-6'}>
                        {rightText}
                    </div>
                </div>
            </div>
        </div>
    </Modal>;
}

export default AddExtrasModal;
