// @flow
import React, {Component}                                   from 'react';
import Header                                               from "../../components/Header";
import {Redirect, withRouter}                               from "react-router";
import Translator                                           from "../../components/Translator";
import * as Sentry                                          from '@sentry/browser';
import {connect}                                            from "react-redux";
import {onLoadReservationSuccess, showError, toggleSpinner} from "../../redux/actions";
import Footer                                               from "../../components/Footer";
import {goToStartPage, goToThankyou}                        from "../Types/Dispatcher";
import * as Constants                                       from "../../constants/constants";
import {CHECKIN_STATE_READY}                                from "../../constants/constants";
import KeycardControlsLayoutSimple
                                                            from "../../components/pages/RoomInformation/KeycardControlsLayoutSimple";
import KeycardControlsLayoutStepByStep
                                                            from "../../components/pages/RoomInformation/KeycardControlsLayoutStepByStep";
import KeycardControlsLayoutNewCard
                                                            from "../../components/pages/RoomInformation/KeycardControlsLayoutNewCard";

class RoomInformation extends Component
{
    defaultState = {
        keycardSuccess:  false,
        errorMessage:    '',
        keyResponseCode: '',
        tries:           0,
        keycards:        0
    };

    constructor(props)
    {
        super(props);
        this.state = this.defaultState;
    }

    submitRequestKeyCard = () => {
        this.props.toggleSpinner(true, '');

        let reservationId = this.props.reservation.id;
        let roomNr        = String(this.props.roominformations.name);
        let checkIn       = this.props.reservation.arrival;
        let checkOut      = this.props.reservation.departure;

        this.props.apiClient.requestKeyCard(reservationId, roomNr, checkIn, checkOut).then(response => {
            this.props.toggleSpinner(false, '');
            let keyResponse = response.keyResponse;
            if (typeof keyResponse === 'undefined' || keyResponse === null || typeof keyResponse.code !== 'string') {
                this.props.showError('roominformation.errors.createcard', () => {
                    goToStartPage(Constants.PAGE_ROOMINFORAMTION)
                });
            } else if (keyResponse.code !== 'success') {
                if (this.state.tries >= 3) {
                    this.props.showError('roominformation.errors.createcard', () => {
                        goToStartPage(Constants.PAGE_ROOMINFORAMTION)
                    })
                } else {
                    //TODO: modal mit nachricht und btn
                    this.setState({
                                      keyResponseCode: keyResponse.code,
                                      tries:           this.state.tries + 1
                                  });
                }
            } else {
                this.setState({
                                  keyResponseCode: 'success',
                                  keycardSuccess:  true,
                                  keycards:        this.state.keycards + 1
                              });
            }
        }).catch((err) => {
            // FIXME: unhandled Exception
            Sentry.captureException(err);
            this.props.toggleSpinner(false, '');
        });
    }

    render()
    {
        if (!this.props.reservation.id) {
            return <Redirect to={'login'} />;
        }
        let checkin   = this.props.roominformations.checkin;
        let roomNr    = this.props.roominformations.name;
        let guestName = '';
        if (this.props.pagesConfig.thankyou.showName) {
            guestName = this.props.reservation.primaryGuest.firstName ?? '';
            guestName += ' ' + this.props.reservation.primaryGuest.lastName ?? '';
        }

        return (<div className="page page-roominformation">
            <Header showSteps={4} />
            <div className="app-content">
                <div className="container">
                    <div className="page-header">
                        {(() => {
                            switch (checkin) {
                                case 'success':
                                    return <Translator content={"checkin.text.message"} />;
                                default:
                                    return <Translator content={"checkin.text.consultfrontofficeHeadline"} />;
                            }
                        })()}
                    </div>
                    <div className="room-number">
                        <div className={'__name'}>
                            <Translator content={'words.room'} />
                        </div>

                        <div className="__inner">
                            {roomNr}
                        </div>

                        <div className={'__name'}>
                            {guestName}
                        </div>
                    </div>
                    {(() => {
                        switch (checkin) {
                            case 'success':
                                let controlsLayout = this.props.keycardConfig.controlsLayout;

                                if (this.state.keycardSuccess && this.state.keycards < 2) {
                                    controlsLayout = 'newcard';
                                } else if (this.state.keycards >= 2) {
                                    controlsLayout = 'done';
                                }

                                switch (controlsLayout) {
                                    case 'stepbystep':
                                        let status;
                                        switch (this.state.keyResponseCode) {
                                            case "":
                                                status = '';
                                                break;
                                            case "success":
                                                status = 'success';
                                                break;
                                            case "cardlimit":
                                            case "timeout":
                                            default:
                                                status = 'error';
                                                break;
                                        }

                                        return <KeycardControlsLayoutStepByStep
                                            status={status}
                                            onClick={this.submitRequestKeyCard}
                                        />;
                                    case 'done':
                                    case 'newcard':

                                        let onNewCard = () => {
                                            this.setState({
                                                              ...this.defaultState,
                                                              keycards: this.state.keycards
                                                          })
                                        }
                                        if (controlsLayout === 'done') {
                                            onNewCard = null;
                                        }

                                        return <KeycardControlsLayoutNewCard
                                            onFinishCheckin={() => {
                                                goToThankyou(Constants.PAGE_ROOMINFORAMTION)
                                            }}
                                            onNewCard={onNewCard}
                                        />;
                                    case 'simple':
                                    default:
                                        return <KeycardControlsLayoutSimple
                                            onClick={this.submitRequestKeyCard}
                                        />;
                                }
                            default:
                                return <div className="room-information">
                                    <div className="row">
                                        <div className="col-10 offset-1">
                                            <div className="consultfrontoffice">
                                                <Translator content={"checkin.text.consultfrontoffice"} />
                                            </div>
                                            <div className={'row'}>
                                                <div className="col-4">
                                                    <button
                                                        onClick={() => {
                                                            goToThankyou(Constants.PAGE_ROOMINFORAMTION)
                                                        }}
                                                        className="btn btn-primary btn-uppercase btn-fullwidth btn-space"
                                                        disabled={this.state.isLoading}
                                                    >
                                                        <Translator content={"checkin.buttons.thankyou"} />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>;
                        }
                    })()}
                </div>
            </div>
            <Footer />
        </div>);
    }
}

//roominformations


export default withRouter(connect(state => {
    return {
        pagesConfig:      state.app.pagesConfig,
        apiClient:        state.app.apiClient,
        reservation:      state.apaleo.reservation,
        roominformations: state.apaleo.roominformations,
        keycardConfig:    state.app.keycardConfig
    }
}, {
                                      onLoadReservationSuccess,
                                      toggleSpinner,
                                      showError
                                  })(RoomInformation));
