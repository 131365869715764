import React                            from 'react';
import ReactDOM                         from 'react-dom';
import App                              from './App';
import * as serviceWorker               from './serviceWorker';
import {BrowserRouter}                  from 'react-router-dom'
import {Provider}                       from 'react-redux';
import langEn                           from './languages/en.json';
import langDe                           from './languages/de.json';
import langEnCountries                  from './languages/en_countries.json';
import langDeCountries                  from './languages/de_countries.json';
import store                            from './redux/store';
import {globalAppState as initialState} from "./redux/state";
import packageJson                      from '../package.json';

import i18n               from "i18next";
import {initReactI18next} from "react-i18next";

import * as Sentry from '@sentry/browser';

Sentry.init({dsn: initialState.app.config.sentry});
Sentry.setTag("version", packageJson.version);

let counterpart = require('counterpart');
counterpart.registerTranslations('en', {...langEn, ...langEnCountries});
counterpart.registerTranslations('de', {...langDe, ...langDeCountries});
counterpart.setLocale(initialState.app.config.language);

let options = {
    lng:       'de',
    languages: ['en', 'de'],
    resources: {
        en:            {
            translation: {...langEn, ...langEnCountries}
        },
        de:            {
            translation: {...langDe, ...langDeCountries}
        },
        interpolation: {
            escapeValue: false
        },
        fallbackLng:   'en'
    }
};

i18n.use(initReactI18next).init(options);

ReactDOM.render(<Provider store={store}>
    <BrowserRouter>
        <App />
    </BrowserRouter>
</Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
