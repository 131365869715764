// @flow
import React, {Component}                                 from 'react';
import Translator                                         from "../../components/Translator";
import Header                                             from "../../components/Header";
import {Redirect, withRouter}                             from "react-router";
import * as Sentry                                        from '@sentry/browser';
import CountrySelect                                      from "../../components/CountrySelect";
import BackButton                                         from "../Buttons/BackButton";
import {connect}                                          from "react-redux";
import {
    checkInNow, reservationNeedsUpdate, showError, toggleSpinner, updateReservationAndGoToPayment, updateSignedGuests
}                                                         from "../../redux/actions";
import {goToRoominformation, goToStartPage, goToThankyou} from "../Types/Dispatcher";
import * as Constants                                     from "../../constants/constants";
import Footer                                             from "../../components/Footer";
import InputWithLabel                                     from "../../components/InputWithLabel";

let counterpart = require('counterpart');

class Citytax extends Component
{

    cityTaxRequired = {
        leisure:  {
            travelReason: true,
            company:      false,
            address:      false,
            postalCode:   false,
            city:         false,
            country:      false
        },
        business: {
            travelReason: true,
            company:      true,
            address:      true,
            postalCode:   true,
            city:         true,
            country:      true
        }
    };

    constructor(props)
    {
        super(props);

        this.state = {
            errors:                {},
            travelReasonShowState: "",
            citytaxAddress:        {
                lastName:     "",
                firstName:    "",
                birthDate:    "",
                signature:    "",
                travelReason: "",
                company:      "",
                address:      "",
                postalCode:   "",
                city:         "",
                country:      ""
            }
        };

        this.handleChange         = this.handleChange.bind(this);
        this.handleSubmit         = this.handleSubmit.bind(this);
        this.signatureClear       = this.signatureClear.bind(this);
        this.onTravelReasonChange = this.onTravelReasonChange.bind(this);
    }

    componentDidMount()
    {
        if (!this.props.reservation.id) {
            return <Redirect to={'login'} />;
        }
        let reservation = this.props.reservation;
        let folio       = reservation._meta.folio;
        if (folio !== null) {
            let address = this.getFolioAddress(folio.debitor);

            this.setState({
                              citytaxAddress: {
                                  ...this.state.citytaxAddress,
                                  travelReason: "",
                                  company:      address.company || "",
                                  address:      address.address || "",
                                  postalCode:   address.postalCode || "",
                                  city:         address.city || "",
                                  country:      address.country || ""
                              }
                          });
        }
    }

    handleSubmit(event)
    {
        event.preventDefault();
        event.stopPropagation();
        if (this.formIsValid()) {
            this.props.toggleSpinner(true, '');

            this.sendChanges();
        }
        return false;
    }

    sendChanges()
    {
        let thisPage = this;
        this.props.toggleSpinner(true, '');
        this.props.reservationNeedsUpdate(true);
        let guest = this.props.reservation.primaryGuest;

        guest.citytax = this.state.citytaxAddress;

        this.props.apiClient.updateCitytax(this.props.reservation.id, guest).then((response) => {
            if (this.props.pagesConfig.skipPayment) {
                this.props.checkInNow(Constants.PAGE_CITYTAX).then((response) => {
                    if (response.status === 'checkinSkipped') {
                        goToThankyou(Constants.PAGE_CITYTAX);
                    } else {
                        goToRoominformation(Constants.PAGE_CITYTAX);
                    }
                }).catch(function(response) {
                    //we need to catch the response or app will crash
                });
            } else {
                thisPage.props.toggleSpinner(false, '');
                if (response.message === 'ok') {
                    // we need to reaload the reservation before we go to the payment page
                    this.props.updateReservationAndGoToPayment(Constants.PAGE_CITYTAX);
                } else {
                    thisPage.props.showError('citytax.error');
                    setTimeout(() => {
                        goToStartPage(Constants.PAGE_ROOMINFORAMTION);
                    }, 10000)
                }
            }
        }).catch((err) => {
            // FIXME: unhandled Exception
            Sentry.captureException(err);
            this.props.toggleSpinner(false, '');
        });
    }

    handleChange(event)
    {
        this.setState({
                          citytaxAddress: {
                              ...this.state.citytaxAddress,
                              [event.target.name]: event.target.value
                          }
                      });
    }

    signatureClear(event)
    {
        event.preventDefault();
        if (this.signaturePad) {
            this.signaturePad.clear();
        }
    }

    formIsValid()
    {
        let isValid        = true;
        let cityTaxForm    = this.state.citytaxAddress;
        let errors         = {};
        let requiredFields = this.cityTaxRequired.leisure;

        if (this.state.citytaxAddress.travelReason === "business") {
            requiredFields = this.cityTaxRequired.business;
        }

        for (let field in requiredFields) {
            if (requiredFields.hasOwnProperty(field)) {
                let required = requiredFields[field];
                if (required && !(cityTaxForm.hasOwnProperty(field) && cityTaxForm[field])) {
                    isValid       = false;
                    errors[field] = counterpart("forms.errors.required");
                    this.props.showError("messages.errors.required");
                }
            }
        }

        this.setState({errors: errors});
        return isValid;
    }

    isFieldRequired(field)
    {
        let required = false;
        if (this.cityTaxRequired.hasOwnProperty(field)) {
            required = this.cityTaxRequired[field];
        }

        return required;
    }

    onTravelReasonChange(event)
    {
        let name  = event.target.name;
        let value = event.target.value;
        this.setState({
                          citytaxAddress: {
                              ...this.state.citytaxAddress,
                              [name]: value
                          }
                      }, function() {
            this.setState({travelReasonShowState: value});
        });
    }

    render()
    {
        if (!this.props.reservation.id) {
            return <Redirect to={'login'}/>;
        }

        return (<div className="page page-citytax">
            <Header
                showSteps={3}
            />
            <div className="app-content">
                <div className="container">
                    <div className={'title'}>
                        <Translator content={'citytax.header'}/>
                    </div>
                    <div className="__content">
                        <div className="row">
                            <div className="col-4 __left-col">
                                <div className="bodytext-app">
                                    <Translator
                                        content="citytax.text.1"/>
                                </div>
                                <div
                                    className={(this.state.travelReasonShowState === "business") ? "bodytext-app"
                                                                                                 : "bodytext-app _cloak"}>
                                    <Translator
                                        content="citytax.text.2"/>
                                </div>
                            </div>
                            <div className="col-1">

                            </div>
                            <div className={"col-7 __right-col" +
                                (this.state.travelReasonShowState !== "" ? " _" + this.state.travelReasonShowState
                                                                         : "")}>
                                <div className="custom-radio-container-wrap">
                                    <label htmlFor="travelReason-leisure" className="custom-radio-container">
                                        <Translator className="__label" content="citytax.leisure"/>
                                        <input
                                            type="radio"
                                            name="travelReason"
                                            id="travelReason-leisure"
                                            value={"leisure"}
                                            checked={this.state.citytaxAddress.travelReason === "leisure"}
                                            onChange={this.onTravelReasonChange}/>
                                        <span className="__checkmark"/>
                                    </label>
                                    <label htmlFor="travelReason-business" className="custom-radio-container">
                                        <Translator className="__label" content="citytax.business"/>
                                        <input
                                            type="radio"
                                            name="travelReason"
                                            id="travelReason-business"
                                            value={"business"}
                                            checked={this.state.citytaxAddress.travelReason === "business"}
                                            onChange={this.onTravelReasonChange}/>
                                        <span className="__checkmark"/>
                                    </label>
                                </div>

                                {(() => {
                                    switch (this.state.travelReasonShowState) {
                                        case 'business':
                                            return <div className="text-container">
                                                <div className="form-group">
                                                    <InputWithLabel
                                                        error={this.state.errors.hasOwnProperty('company')}
                                                        readOnly={false}
                                                        value={this.state.citytaxAddress.company}
                                                        label={'fields.company'}
                                                        required={true}
                                                        updateInputValue={(company) => this.setState({
                                                                                                         citytaxAddress: {
                                                                                                             ...this.state.citytaxAddress,
                                                                                                             company: company
                                                                                                         }
                                                                                                     })}
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <InputWithLabel
                                                        error={this.state.errors.hasOwnProperty('address')}
                                                        readOnly={false}
                                                        value={this.state.citytaxAddress.address}
                                                        label={'fields.address'}
                                                        required={true}
                                                        updateInputValue={(address) => this.setState({
                                                                                                         citytaxAddress: {
                                                                                                             ...this.state.citytaxAddress,
                                                                                                             address: address
                                                                                                         }
                                                                                                     })}
                                                    />
                                                </div>
                                                <div className="row">
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <InputWithLabel
                                                                error={this.state.errors.hasOwnProperty('postalCode')}
                                                                readOnly={false}
                                                                value={this.state.citytaxAddress.postalCode}
                                                                label={'fields.postalCode'}
                                                                required={true}
                                                                updateInputValue={(postalCode) => this.setState({
                                                                                                                    citytaxAddress: {
                                                                                                                        ...this.state.citytaxAddress,
                                                                                                                        postalCode: postalCode
                                                                                                                    }
                                                                                                                })}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="form-group">
                                                            <InputWithLabel
                                                                error={this.state.errors.hasOwnProperty('city')}
                                                                readOnly={false}
                                                                value={this.state.citytaxAddress.city}
                                                                label={'fields.city'}
                                                                required={true}
                                                                updateInputValue={(city) => this.setState({
                                                                                                              citytaxAddress: {
                                                                                                                  ...this.state.citytaxAddress,
                                                                                                                  city: city
                                                                                                              }
                                                                                                          })}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <CountrySelect
                                                        label="fields.country"
                                                        required={true}
                                                        name="country"
                                                        value={this.state.citytaxAddress.country}
                                                        onChange={this.handleChange}
                                                        className={this.state.errors.hasOwnProperty("country")
                                                                   ? "is-invalid" : ""}
                                                    />
                                                </div>
                                            </div>;
                                        case 'leisure':

                                            if (this.props.hotelConfig._meta.citytax === null) {
                                                return '';
                                            }

                                            return <div className="text-container">
                                                <div className="bodytext-app">
                                                    <Translator
                                                        content="citytax.textLeisure.1"
                                                        with={{
                                                            city: this.props.hotelConfig.location.city
                                                        }}
                                                    />
                                                </div>
                                                <div className="bodytext-app">
                                                    {this.props.hotelConfig._meta.citytax.description[this.props.locale]}
                                                </div>
                                                <div className="bodytext-app">
                                                    <Translator
                                                        content="citytax.textLeisure.2"/>
                                                </div>
                                            </div>;
                                        default:
                                            return null;
                                    }
                                })()}
                            </div>
                        </div>
                    </div>
                    <div className={'button-row'}>
                        <BackButton/>
                        <div className="btn">
                            {(() => {
                                let text = null;
                                switch (this.state.travelReasonShowState) {
                                    case 'business':
                                        text = <Translator content="citytax.confirmPayment"/>;
                                        break;
                                    case 'leisure':
                                        text = <Translator content="citytax.ready"/>;
                                        break;
                                    default:
                                        return null;
                                }

                                return <button
                                    onClick={this.handleSubmit}
                                    className="btn btn-primary btn-uppercase btn-fullwidth btn-space">
                                    {text}
                                </button>
                            })()}
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>);
    }

    getFolioAddress(debitor)
    {
        let address = {
            company:    "",
            address:    "",
            postalCode: "",
            city:       "",
            country:    ""
        };

        if (typeof debitor !== 'undefined') {
            if (debitor.address) {
                address.address    = debitor.address.addressLine1;
                address.postalCode = debitor.address.postalCode;
                address.city       = debitor.address.city;
                address.country    = debitor.address.countryCode;
            }

            if (debitor.company) {
                address.company = debitor.company.name;
            }
        }

        return address;
    }
}


export default withRouter(connect(state => {
    return {
        hotelConfig:  state.app.hotelConfig,
        apiClient:    state.app.apiClient,
        pagesConfig:  state.app.pagesConfig,
        reservation:  state.apaleo.reservation,
        signedGuests: state.apaleo.signedGuests,
        locale:       state.app.locale
    }
}, {
                                      showError,
                                      updateSignedGuests,
                                      toggleSpinner,
                                      checkInNow,
                                      reservationNeedsUpdate,
                                      updateReservationAndGoToPayment
                                  })(Citytax));
