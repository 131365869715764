import React                from "react";
import Translator           from "../../Translator";
import Icon                 from "../../Icon";
import Lottie               from "lottie-react";
import animation            from "../../../animations/placeholder_lottie_NOTBUYED";
import keycardButtonStepImg from "../../../assets/brand/keycard_button_step.png";
import {useSelector}        from "react-redux";

const KeycardControlsLayoutStepByStep = (props) => {
    const pagesConfig = useSelector((state) => state.app.pagesConfig);

    let statusMessage = <div>&nbsp;</div>;
    let statusClass   = '';
    let statusIcon    = <Icon icon={'MOOI_Haken'} />;
    switch (props.status) {
        case 'error':
            statusMessage =
                <Translator component={'div'} className={'__text'} content={'roominformation.errors.error'} />;
            statusClass   = '_error';
            statusIcon    = <Icon icon={'clear'} />;
            break;
        case 'success':
            statusClass = '_success';
            break;
        default:
            break;
    }

    //make sure, that there is a space
    statusClass = ' ' + statusClass

    return <div className="keycard-controls _step-by-step">
        <div className={'__step'}>
            <div>
                <Translator className={'__title'} content={'keycard.step'} with={{step: '1'}} />
                <Translator component={'div'} className={'__text'} content={'keycard.steps.takeCard'} />
            </div>
            <Icon icon={'MOOI_Hand_Karte'} />
        </div>
        <div className={'__step'}>
            <div>
                <Translator className={'__title'} content={'keycard.step'} with={{step: '2'}} />
                <Translator component={'div'} className={'__text'} content={'keycard.steps.placeCard'} />
            </div>
            <Icon icon={'MOOI_Geraet_Karte'} />
        </div>
        <div className={'__step _click'} onClick={props.onClick}>
            <div>
                <Translator className={'__title'} content={'keycard.step'} with={{step: '3'}} />
                <Translator component={'div'} className={'__text'} content={'keycard.steps.button'} />
            </div>
            {(() => {
                switch (pagesConfig.roominformation.buttonStep ?? '') {
                    case 'lottie':
                        return <Lottie className={'__button'} animationData={animation} loop={true} autoplay={true} />;
                    case 'png':
                        return <div className={'__button'}><img alt={'button'} src={keycardButtonStepImg} /></div>;
                }
            })()}
        </div>
        <div className={'__step'}>
            <div>
                <Translator className={'__title'} content={'keycard.step'} with={{step: '4'}} />
                <Translator component={'div'} className={'__text'} content={'keycard.steps.beep'} />
            </div>
            <Icon icon={'MOOI_beep'} />
        </div>
        <div className={'__step' + statusClass}>
            <div>
                <Translator className={'__title'} content={'keycard.step'} with={{step: '5'}} />
                {statusMessage}
            </div>
            {statusIcon}
        </div>
    </div>;
}


export default KeycardControlsLayoutStepByStep;
