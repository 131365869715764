import React, {Component} from "react";
import Translator         from "./Translator";
import PropTypes          from 'prop-types';


class InputWithLabel extends Component
{
    constructor(props)
    {
        super(props);
        this.state = {fieldActive: false};

        this.updateInputValue = this.updateInputValue.bind(this);
        this.activateField    = this.activateField.bind(this);
        this.disableField     = this.disableField.bind(this);
    }

    activateField()
    {
        this.setState({
                          fieldActive: true,
                          fieldClass:  ''
                      })
    }

    disableField(e)
    {
        this.setState({
                          fieldActive: false
                      });
        if (e.target.value !== "") {
            this.setState({
                              fieldClass: 'filled'
                          })
        }
    }

    handleFocus()
    {
        this.inputRef.focus();
    }

    updateInputValue(e)
    {
        this.props.updateInputValue(e.target.value);
        this.activateField(e);
        e.preventDefault();
    }

    render()
    {
        let reqSpan = null;
        if (this.props.required) {
            reqSpan = <span className="required">*</span>;
        }

        let fieldClass      = (this.props.value.length > 0 ? 'filled' : '') +
            (this.state.fieldActive ? " field-active" : "");
        let isInputRequired = false;

        if (this.props.isRequired && this.props.value.length === 0) {
            isInputRequired = true;
        }

        if (this.props.isFilled) {
            fieldClass = 'filled';
        }

        let classNameGroup = "field-group";
        if (this.props.error) {
            classNameGroup += " is-invalid";
        }

        let className = 'floating-input';
        if (this.props.className !== '') {
            className += ' ' + this.props.className;
        }
        return (<div className={className}>
            <div className={classNameGroup}>
                <label className={fieldClass}>
                    <Translator content={this.props.label} />{reqSpan}
                </label>
                <input
                    ref={ref => this.inputRef = ref}
                    className={"floating-label " + (isInputRequired ? "_required" : "") +
                        (this.state.fieldActive ? " active " : "")}
                    type={this.props.type}
                    disabled={this.props.readOnly}
                    required={this.props.required}
                    value={this.props.value}
                    onFocus={this.activateField}
                    onBlur={this.disableField}
                    onChange={this.updateInputValue}
                />
            </div>
        </div>);
    }
}

// Definition of LabeledInput component properties
InputWithLabel.propTypes = {
    updateInputValue: PropTypes.func,
    label:            PropTypes.string,
    className:        PropTypes.string,
    value:            PropTypes.string,
    readOnly:         PropTypes.bool,
    required:         PropTypes.bool,
    error:            PropTypes.bool,
    type:             PropTypes.string
};

// Definition of default LabeledInput component properties
InputWithLabel.defaultProps = {
    updateInputValue: (v) => {
    },
    label:            '',
    className:        '',
    value:            '',
    readOnly:         false,
    required:         false,
    error:            false,
    type:             'text'
};

export default InputWithLabel;
