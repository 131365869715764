import defaultConfig from './../config/config.json';
import brandConfig   from './../config/brand.json';

// mergehelper - start
// see: https://thewebdev.info/2021/03/06/how-to-deep-merge-javascript-objects/
const isObject = (item) => {
    return (item && typeof item === 'object' && !Array.isArray(item));
}

const mergeDeep = (target, ...sources) => {
    if (!sources.length) {
        return target;
    }
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) {
                    Object.assign(target, {
                        [key]: {}
                    });
                }
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, {
                    [key]: source[key]
                });
            }
        }
    }

    return mergeDeep(target, ...sources);
}
// mergehelper - end

let config = mergeDeep(defaultConfig, brandConfig);

export const globalAppState = {
    cart:   {
        services: []
    },
    apaleo: {
        reservationNeedsUpdate: false,
        guestCredentials:       {},
        reservation:            {},
        serviceOffers:          [],
        signedGuests:           [],
        roominformations:       {
            checkinState: '',
            name:         ''
        }
    },
    app:    {
        hotelConfig:    {},
        locale:         'de',
        message:        null,
        showLoader:     false,
        loaderMessage:  "",
        idempotencyKey: '',
        apiClient:      null,
        /**
         * TODO: config is not changed? do not add it in redux. just use it directly => prebuild anpassen
         *  or state.config?
         */
        pagesConfig:                     {
            skipCitytax:           config.pagesConfig.skipCitytax,
            skipPayment:           config.pagesConfig.skipPayment,
            skipCheckin:           config.pagesConfig.skipCheckin,
            enableLostkeyWorkflow: config.pagesConfig.enableLostkeyWorkflow,
            enableBookingButton:   config.pagesConfig.enableBookingButton,
            reservationDetails:    config.pagesConfig.reservationDetails,
            roominformation:       config.pagesConfig.roominformation,
            thankyou:              config.pagesConfig.thankyou
        },
        meldescheinConfig:               {
            displayCommentFields:     config.meldescheinConfig.displayCommentFields,
            onlyForPrimaryGuest:      config.meldescheinConfig.onlyForPrimaryGuest,
            displayEditDataButton:    config.meldescheinConfig.displayEditDataButton,
            showAGB:                  config.meldescheinConfig.showAGB,
            showSignatureHeader:      config.meldescheinConfig.showSignatureHeader,
            identNumNotRequiredArray: config.meldescheinConfig.identNumNotRequiredArray
        },
        keycardConfig:                   {
            controlsLayout: config.keycardConfig.controlsLayout
        },
        specificallyCustomerInformation: {
            supportNumber: config.specificallyCustomerInformation.supportNumber,
            bookingUrl:    config.specificallyCustomerInformation.bookingUrl
        },
        config:                          config
    }
};
