import React              from "react";
import {connect}          from "react-redux";
import Price              from "../Price";
import ApaleoTranslator   from "../ApaleoTranslator";
import Translator         from "../Translator";
import {addServiceToCart} from "../../redux/actions";

const ExtrasSelection = (props) => {
    let extras = [];

    for (let i = 0; i < props.serviceOffers.length; i++) {
        let serviceOffer = props.serviceOffers[i];

        if (props.cartServices.some(service => service.id === serviceOffer.service.id)) {
            continue;
        }

        extras.push(<div key={i} className={'__extra'}>
            <div className={'__image'}>
                <img alt={'alt'} src={serviceOffer._meta.image} />
            </div>
            <div className={'__content'}>
                <div className={'__text'}>
                    <div className={'__headline'}><ApaleoTranslator property={serviceOffer.service.name} /></div>
                    <div className={'bodytext-app'}>
                        <ApaleoTranslator property={serviceOffer.service.description} length={120} />
                    </div>
                </div>

                <div className={'__footer'}>
                    <div className={'__price'}>
                        <span><Price currency={serviceOffer.totalAmount.currency}
                                     amount={serviceOffer.totalAmount.grossAmount} /></span>
                        <span><Translator content={'apaleo.pricingUnit.' + serviceOffer.service.pricingUnit} /></span>
                    </div>
                    <div className={'__button'}>
                        <button
                            onClick={() => {
                                props.addServiceToCart({id: serviceOffer.service.id});
                            }}
                            className={'btn btn-primary btn-slim'}>
                            <Translator content={'button.bookNow'} />
                        </button>
                    </div>
                </div>
            </div>
        </div>);
    }

    return (
        <>
            <div className="modal-headline">
                <Translator
                    content={'services.chooseExtras'} />
            </div>
            <div className="modal-content">
                <div className="extras">
                    {extras}
                </div>
            </div>
        </>)
}

ExtrasSelection.defaultProps = {};

export default (connect(state => {
    return {
        serviceOffers: state.apaleo.serviceOffers,
        cartServices:  state.cart.services
    }
}, {addServiceToCart})(ExtrasSelection));
