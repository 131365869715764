import React, {Component} from 'react';
import {connect}          from "react-redux";
import PropTypes          from 'prop-types';
import * as Sentry        from '@sentry/browser';
import AppRoutes          from "./routings/AppRoutes";
import packageJson        from '../package.json';

import TerminalVerification from "./pages/TerminalVerification/TerminalVerification";

import MessageHandler from "./components/MessageHandler";

import {
    changeLocale,
    clearMessage,
    resetTerminal,
    setHotelConfig,
    showMessage,
    showTimeoutMessage,
    showWarning,
    toggleSpinner,
    updateRoominformations
}                     from "./redux/actions";

import './App.css';
import LoadingSpinner                from "./components/LoadingSpinner/LoadingSpinner";
import {goToMainPage, goToStartPage} from "./pages/Types/Dispatcher";
import {history}                     from "./redux/store";
import Modal          from "react-modal";

let translate = require('counterpart');

class App extends Component
{
    onUserActivityForListener    = null;
    _isMounted                   = false;
    // zeiten auf 90/30 sekunden gesetzt, damit gäste mehr Zeit zum raus suchen von Kreditkarten haben
    showInactivityMessageTimeout = 90000;
    goInactiveTimeout            = this.showInactivityMessageTimeout + 30000;

    timeoutIDRedirect = null;
    timeoutIDMessage  = null;

    static contextTypes = {
        router: PropTypes.object.isRequired
    };

    constructor(props)
    {
        super(props);

        this.state = {
            updateRequired:               false,
            redirect:                     false,
            terminalIsInactive:           false,
            showTerminalVerificationPage: false
        };

        this.startTimer                  = this.startTimer.bind(this);
        this.goInactive                  = this.goInactive.bind(this);
        this.checkStorageForTerminalCode = this.checkStorageForTerminalCode.bind(this);

        translate.onLocaleChange(function(newLocale, oldLocale) {
            this.props.changeLocale(newLocale);
        }.bind(this));
    }

    componentWillReceiveProps()
    {
        this.resetDefaultStates();
    }

    componentWillUpdate()
    {
        if (this.state.updateRequired && this.isHomeScreen()) {
            this.forceUpdate(this.appUpdate)
        }
    }

    componentDidMount()
    {
        this.checkStorageForTerminalCode();
        let app                        = this;
        this.onUserActivityForListener = this.onUserActivity.bind(this);
        window.addEventListener('mousedown', this.onUserActivityForListener);

        this.props.apiClient.setEventListener('terminalCodeInvalid', function() {
            localStorage.removeItem('verificationCode');
            app.checkStorageForTerminalCode();
        });

        this.props.apiClient.setEventListener('updateRequired', function() {
            if (!app.state.updateRequired) {
                app.setState({updateRequired: true});
                if (this.isHomeScreen()) {
                    app.forceUpdate(app.appUpdate)
                }
            }
        }.bind(this));

        this.startTimer();


        /* fix:
         * Warning: react-modal: App element is not defined. Please use `Modal.setAppElement(el)` or set `appElement={el}`.
         * This is needed so screen readers don't see main content when modal is opened. It is not recommended, but you can
         * opt-out by setting `ariaHideApp={false}`.
         * see: https://github.com/reactjs/react-modal/issues/133
         */
        Modal.setAppElement('body');
        this._isMounted = true;
    }

    componentWillUnmount()
    {
        this._isMounted = false;
        window.clearTimeout(this.timeoutIDRedirect);
        window.clearTimeout(this.timeoutIDMessage);
        window.removeEventListener('mousedown', this.onUserActivityForListener);
    }

    appUpdate = () => {
        this.setState({updateRequired: false}, () => {
            window.location.reload(true);
        })
    };

    componentDidCatch(error, errorInfo)
    {
        this.setState({error});
        Sentry.withScope(scope => {
            Object.keys(errorInfo).forEach(key => {
                scope.setExtra(key, errorInfo[key]);
            });
            Sentry.captureException(error);
        });
    }

    resetDefaultStates()
    {
        this.props.clearMessage();
    }

    startTimer()
    {
        this.resetDefaultStates();
        this.timeoutIDRedirect = window.setTimeout(this.goInactive, this.goInactiveTimeout);
        this.timeoutIDMessage  = window.setTimeout(this.showInactivityMessage, this.showInactivityMessageTimeout);
    }

    isHomeScreen = () => {
        let pathname = history.location.pathname;
        return pathname === "" || pathname === "/";
    };

    onUserActivity()
    {
        window.clearTimeout(this.timeoutIDRedirect);
        window.clearTimeout(this.timeoutIDMessage);
        this.startTimer();
    }

    goInactive()
    {
        if (!this.isHomeScreen()) {
            this.props.resetTerminal();
            goToStartPage();
        } else {
            this.startTimer();
        }
    }

    showInactivityMessage = () => {
        if (!this.isHomeScreen()) {
            document.activeElement.blur();
            this.props.showTimeoutMessage(this.showInactivityMessageTimeout - this.goInactiveTimeout);
        }
    }

    async checkStorageForTerminalCode()
    {
        this.props.toggleSpinner(true);
        let terminalCode = localStorage.getItem('verificationCode');
        if (terminalCode) {
            this.props.apiClient.setTerminalCode(terminalCode);
            let config = await this.props.apiClient.hotelConfig(terminalCode);
            if (typeof config.error === 'undefined') {
                this.setState({showTerminalVerificationPage: false});
                this.props.setHotelConfig(config);
            } else {
                this.setState({showTerminalVerificationPage: true});
            }
        } else {
            this.setState({showTerminalVerificationPage: true});
        }
        this.props.toggleSpinner(false);
    }

    render()
    {
        return (<div className="App">
            {this.state.showTerminalVerificationPage ? (<TerminalVerification
                checkStorageForTerminalCode={this.checkStorageForTerminalCode}
            />) : false}
            <MessageHandler/>
            <LoadingSpinner/>

            <AppRoutes/>
            <div style={{
                position:   'absolute',
                right:      0,
                bottom:     0,
                opacity:    0.1,
                userSelect: "none"
            }}>{packageJson.version}</div>
        </div>);
    }
}

export default connect(state => {
    return {
        pathname:    state.router.location.pathname,
        apiClient:   state.app.apiClient,
        reservation: state.apaleo.reservation,
        signedGuest: state.apaleo.signedGuest
    }
}, {
                           setHotelConfig,
                           updateRoominformations,
                           toggleSpinner,
                           resetTerminal,
                           changeLocale,
                           showWarning,
                           showMessage,
                           showTimeoutMessage,
                           clearMessage
                       })(App);
