import {combineReducers} from "redux";
import {connectRouter}   from 'connected-react-router'
import {appReducer}      from "./appReducer";
import {apaleoReducer}   from "./apaleoReducer";
import {cartReducer}     from "./cartReducer";

const createRootReducer = (history) => combineReducers({
                                                           router: connectRouter(history),
                                                           app:    appReducer,
                                                           apaleo: apaleoReducer,
                                                           cart:   cartReducer
                                                       });

export default createRootReducer;
