import React      from "react";
import Translator from "../../Translator";
import Icon       from "../../Icon";

const KeycardControlsLayoutNewCard = (props) => {
    return <div className={'keycard-controls _newcard'}>
        <Icon icon={'MOOI_Haken'}/>
        <div className={'button-row'}>
            {(() => {
                if (props.onNewCard !== null) {
                    return <button
                        onClick={props.onNewCard}
                        className={'btn btn-secondary'}
                    >
                        <Translator content={'checkin.buttons.newcard'}/>
                    </button>;
                } else {
                    // insert dummy for correct style (flexbox used)
                    return <div/>;
                }
            })()}

            <button
                onClick={props.onFinishCheckin}
                className={'btn btn-primary'}
            >
                <Translator content={'checkin.buttons.finish'}/>
            </button>
        </div>
    </div>;
}


export default KeycardControlsLayoutNewCard;
