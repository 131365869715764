import React, {Component} from "react";
import PropTypes          from 'prop-types';
import Translator         from "./Translator";
import Select             from 'react-select'

let counterpart = require('counterpart');

/* @formatter:off */
const countries = ["AL", "DZ", "AS", "AD", "AO", "AI", "AQ", "AG", "AR", "AM", "AW", "AU", "AT", "AZ", "BH", "BD", "BB", "BY", "BE", "BZ", "BJ", "BM", "BT", "BO", "BQ", "BA", "BW", "BV", "BR", "IO", "VG", "BN", "BG", "BF", "BI", "KH", "CM", "CA", "CV", "KY", "CF", "TD", "CL", "CN", "CX", "CC", "CO", "KM", "CD", "CG", "CK", "CR", "HR", "CU", "CW", "CY", "CZ", "CI", "DK", "DJ", "DM", "DO", "EC", "EG", "SV", "GQ", "ER", "EE", "ET", "FK", "FO", "FJ", "FI", "FR", "GF", "PF", "TF", "GA", "GM", "GE", "DE", "GH", "GI", "GR", "GL", "GD", "GP", "GU", "GT", "GG", "GN", "GW", "GY", "HT", "HM", "HN", "HK", "HU", "IS", "IN", "ID", "IR", "IQ", "IE", "IM", "IL", "IT", "JM", "JP", "JE", "JO", "KZ", "KE", "KI", "KW", "KG", "LA", "LV", "LB", "LS", "LR", "LY", "LI", "LT", "LU", "MO", "MK", "MG", "MW", "MY", "MV", "ML", "MT", "MH", "MQ", "MR", "MU", "YT", "MX", "FM", "MD", "MC", "MN", "ME", "MS", "MA", "MZ", "MM", "NA", "NR", "NP", "NL", "NC", "NZ", "NI", "NE", "NG", "NU", "NF", "KP", "MP", "NO", "OM", "PK", "PW", "PS", "PA", "PG", "PY", "PE", "PH", "PN", "PL", "PT", "PR", "QA", "RE", "RO", "RU", "RW", "BL", "SH", "KN", "LC", "MF", "PM", "VC", "WS", "SM", "SA", "SN", "RS", "SC", "SL", "SG", "SX", "SK", "SI", "SB", "SO", "ZA", "GS", "KR", "SS", "ES", "LK", "SD", "SR", "SJ", "SZ", "SE", "CH", "SY", "ST", "TW", "TJ", "TZ", "TH", "BS", "TL", "TG", "TK", "TO", "TT", "TN", "TR", "TM", "TC", "TV", "VI", "UG", "UA", "AE", "GB", "US", "UM", "UY", "UZ", "VU", "VA", "VE", "VN", "WF", "EH", "YE", "ZM", "ZW", "AX"];
/* @formatter:on */

class CountrySelect extends Component
{
    constructor(props)
    {
        super(props);

        this.state = {
            focused:     false,
            fieldActive: false,
            fieldClass:  (this.props.value !== '' ? 'filled' : '')
        };

        this.handleChange  = this.handleChange.bind(this);
        this.activateField = this.activateField.bind(this);
        this.disableField  = this.disableField.bind(this);
    }

    activateField()
    {
        this.setState({
                          fieldActive: true,
                          fieldClass:  ''
                      })
    }

    disableField(selected)
    {
        this.setState({
                          fieldActive: false
                      });
        if (selected.value !== "") {
            this.setState({
                              fieldClass: 'filled'
                          })
        }
    }

    handleChange(selected)
    {
        this.props.onChange({
                                target: {
                                    name:  this.props.name,
                                    value: selected.value
                                }
                            });
    }

    render()
    {
        let reqSpan = null;
        if (this.props.required) {
            reqSpan = <span className="required">*</span>;
        }
        let classNameGroup = "field-group";
        if (this.state.focused || this.state.value !== '') {
            classNameGroup += "";
        }

        let value           = {
            value: '',
            label: ''
        };
        let sortedCountries = [
            {
                value: '',
                label: '  '
            }
        ];
        for (let i = 0; i <= countries.length; i++) {
            sortedCountries.push({
                                     value: countries[i],
                                     label: counterpart('countries.' + countries[i])
                                 });
            if (countries[i] === this.props.value) {
                value.value = countries[i];
                value.label = counterpart('countries.' + countries[i]);
            }
        }

        sortedCountries.sort(function(a, b) {
            return ((a.label < b.label) ? -1 : ((a.label > b.label) ? 1 : 0));
        });


        let classNameLabel = (this.state.fieldClass) + (this.state.fieldActive ? " field-active" : "");

        let classNameSelect = this.props.className;

        if (Object.keys(this.props.errors).length > 0 && value.value === '') {
            classNameSelect += ' _required';
        }

        return (<div className={'floating-input _country-select'}>
            <div className={classNameGroup}>
                <label
                    htmlFor={this.props.name}
                    className={classNameLabel}
                >
                    <Translator content={this.props.label}/>{reqSpan}
                </label>
                <Select
                    required={this.props.required}
                    className={"floating-label react-select-container " + classNameSelect}
                    classNamePrefix={'react-select'}
                    id={this.props.name}
                    name={this.props.name}
                    value={value}
                    onChange={this.handleChange}
                    onFocus={this.activateField}
                    onBlur={this.disableField}
                    disabled={this.props.readOnly}
                    options={sortedCountries}
                />
            </div>
        </div>);
    }
}

// Definition of LabeledInput component properties
CountrySelect.propTypes = {
    className:    PropTypes.string, // Optional css class name string
    errormessage: PropTypes.string, // Optional error message string
    // formValidation: PropTypes.func, // Optional form validation function
    label:          PropTypes.string.isRequired, // Required input label
    name:           PropTypes.string.isRequired, // Required name (as identifier)
    onChange:       PropTypes.func, // Optional on value change function (to update global state)
    readOnly:       PropTypes.bool, // Optional read only modifier
    spawnWithError: PropTypes.bool, // Optional error display on first render (spawn)
    required:       PropTypes.bool, // Optional error display on first render (spawn)
    type:           PropTypes.string, // Optional type of the input
    value:          PropTypes.string // Optional default input value
};

// Definition of default LabeledInput component properties
CountrySelect.defaultProps = {
    className:    "",
    errormessage: "There seems to be an error in this input.", // formValidation: (errors, fieldName) => {
    // },
    onChange:  (event) => {
    },
    readOnly:  false,
    required:  false,
    minlength: 0, // spawnWithError: false,
    type:      "",
    label:     "",
    value:     "",
    errors:    {}
};

export default CountrySelect;
