import React, {useState} from "react";
import i18next           from "i18next";
import counterpart       from "counterpart";

const LanguageMenu = (props) => {
    const [rerender, setRerender] = useState(false);
    i18next.on('languageChanged', function(lng) {
        setRerender(!rerender);
    })

    let changeLanguage = (lang) => {
        counterpart.setLocale(lang);
        i18next.changeLanguage(lang);
    }

    const lang = i18next.language;
    return <ul className="language-menu">
        <li className={'language ' + (lang === 'de' ? 'active' : '')}>
                    <span onClick={(e) => changeLanguage('de')}>
                        DE
                    </span>
        </li>
        <li className={'language ' + (lang === 'en' ? 'active' : '')}>
                    <span onClick={(e) => changeLanguage('en')}>
                        EN
                    </span>
        </li>
    </ul>;
}

export default LanguageMenu;
