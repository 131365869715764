// @flow
import React, {Component}                                                           from 'react';
import Translator                                                                   from "../../components/Translator";
import * as Sentry                                                                  from '@sentry/browser';
import BackButton                                                                   from "../Buttons/BackButton";
import {connect}                                                                    from "react-redux";
import {withRouter}                                                                 from "react-router";
import {onLoadReservationSuccess, showError, toggleSpinner, updateRoominformations} from "../../redux/actions";
import {goToRoominformation}                                                        from "../Types/Dispatcher";
import * as Constants                                                               from "../../constants/constants";
import Header                                                                       from "../../components/Header";
import ManualDateInput
                                                                                    from "../../components/ManualDateInput";
import moment                                                                       from "moment";

let counterpart = require('counterpart');

class LostCard extends Component
{

    formLoginRequired = {
        roomnumber:    true,
        lastName:      true,
        departureDate: true
    };

    constructor(props)
    {
        super(props);

        this.state = {
            errors:       {},
            verification: {
                roomnumber:    process.env.REACT_APP_ROOMNUMBER ?? '',
                lastName:      process.env.REACT_APP_LASTNAME ?? '',
                departureDate: process.env.REACT_APP_CHECKOUT ?? ''
            }
        };

        this.submitVerification = this.submitVerification.bind(this);
        this.handleSubmit       = this.handleSubmit.bind(this);
        this.handleChange       = this.handleChange.bind(this);
    }

    submitVerification()
    {
        let verification = this.state.verification;
        this.props.toggleSpinner(true, '');

        this.props.apiClient.lostCardVerification(
            verification.roomnumber,
            verification.lastName,
            verification.departureDate
        ).then(response => {
            this.props.toggleSpinner(false, '');
            if (response.status === 'success') {
                this.props.onLoadReservationSuccess({
                                                        pathname:         'roominformation',
                                                        guestCredentials: {
                                                            bookingId: response.reservation.id ?? '',
                                                            lastName:  verification.lastName ?? ''
                                                        },
                                                        reservation:      response.reservation
                                                    });
                this.props.updateRoominformations({
                                                      roominformations: {
                                                          checkin: 'success',
                                                          name:    response.unitName
                                                      }
                                                  });
                goToRoominformation(Constants.PAGE_LOSTCARD);
            } else {
                this.props.showError('lostcard.errors.noMatch');
                return false;
            }
        }).catch((err) => {
            // FIXME: unhandled Exception
            Sentry.captureException(err);
            this.props.toggleSpinner(false, '');
        });
    }

    handleSubmit(event)
    {
        event.preventDefault();
        event.stopPropagation();

        if (this.formIsValid()) {
            this.submitVerification()
        }
        return false;
    }

    handleChange(event)
    {
        let verification                = this.state.verification;
        verification[event.target.name] = event.target.value;

        this.handleVerificationChanged(verification);
    }

    handleVerificationChanged(changedVerification)
    {
        this.setState({verification: changedVerification});
    }

    formInputTextWithLabel(label: string, name: string, value: string, type: string)
    {
        let className = "form-control";

        if (this.state.errors.hasOwnProperty(name)) {
            className += " is-invalid";
        }

        return (<div className="form-group">
            <label className="control-label">
                <Translator content={label} />
            </label>
            <div>
                <input type={type}
                       className={className}
                       value={value}
                       name={name}
                       onChange={this.handleChange}
                       autoComplete="off"
                       placeholder=" " />
                <i className="icon icon-terminal-group hidden" />
            </div>
        </div>);
    }

    formIsValid()
    {
        let isValid      = true;
        let verification = this.state.verification;
        let errors       = {};
        for (let field in this.formLoginRequired) {
            if (this.formLoginRequired.hasOwnProperty(field)) {
                let required = this.formLoginRequired[field];
                if (required && !(verification.hasOwnProperty(field) && verification[field])) {
                    isValid       = false;
                    errors[field] = counterpart("forms.errors.required");
                    this.props.showError("messages.errors.required");
                }
            }
        }
        this.setState({errors: errors});
        return isValid;
    }

    isFieldRequired(field)
    {
        let required = false;
        if (this.formLoginRequired.hasOwnProperty(field)) {
            required = this.formLoginRequired[field];
        }

        return required;
    }

    render()
    {
        return (<div className="page page-lostcard">
            <Header
                headerTitle="pageTitles.lostcardPage"
            />
            <div className="app-content">
                <div className="container">
                    <div className="__content">
                        <div className="row">
                            <div className="col-6">
                                <div className="bodytext-app"><Translator
                                    content={"lostcard.text.description"} /></div>
                            </div>
                            <div className="col-6">
                                <div className="terminal-form">
                                    <div className="row">
                                        <div className="col-3">
                                            {this.formInputTextWithLabel(
                                                "fields.roomnumber",
                                                'roomnumber',
                                                this.state.verification.roomnumber,
                                                'text'
                                            )}
                                        </div>
                                        <div className="col-9">
                                            {this.formInputTextWithLabel(
                                                "fields.lastName",
                                                'lastName',
                                                this.state.verification.lastName,
                                                'text'
                                            )}
                                        </div>
                                        <div className="col-12">
                                            <ManualDateInput
                                                id={'fields.departureDate'}
                                                label={'fields.departureDate'}
                                                value={this.state.verification.departureDate}
                                                name="departureDate"
                                                required={true}
                                                onChange={this.handleChange}
                                                maxDate={moment().format('y-m-d')}
                                                error={typeof this.state.errors['departureDate'] !== 'undefined'}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <BackButton />
                            </div>
                            <div className="col-4 offset-4">
                                <button
                                    onClick={this.handleSubmit}
                                    className="btn btn-primary btn-uppercase btn-fullwidth btn-space">
                                    <Translator content={"lostcard.buttons.submit"} />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
    }
}


export default withRouter(connect(state => {
    return {
        apiClient:   state.app.apiClient,
        pagesConfig: state.app.pagesConfig
    }
}, {
                                      toggleSpinner,
                                      showError,
                                      onLoadReservationSuccess,
                                      updateRoominformations
                                  })(LostCard));
