import React               from "react";
import {connect}           from "react-redux";
import ReservationDetails  from "./ReservationContainer/ReservationDetails";
import ReservationProperty from "./ReservationContainer/ReservationProperty";
import ReservationOverview from "./ReservationContainer/ReservationOverview";

const ReservationContainer = (props) => {
    return <div className="reservation-container">
        <ReservationOverview/>
        <ReservationProperty/>
        <ReservationDetails/>
    </div>;
}

export default connect(state => {
    return {
        hotelConfig:       state.app.hotelConfig,
        meldescheinConfig: state.app.meldescheinConfig,
        reservation:       state.apaleo.reservation,
        pagesConfig:       state.app.pagesConfig,
        locale:            state.app.locale
    }
}, {})(ReservationContainer);
