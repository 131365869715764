import {globalAppState as initialState} from "../state";
import {
    CHANGE_LOCALE,
    CLEAR_MESSAGE,
    GENERATE_IDEMPOTENCY_KEY,
    RESET_TERMINAL,
    SET_HOTEL_CONFIG,
    SHOW_MESSAGE,
    TOGGLE_SPINNER
}                                       from "../../constants";
import {LOCATION_CHANGE}                from 'connected-react-router';
import {v4 as uuidv4}                   from 'uuid';


export const appReducer = (state = initialState.app, action) => {
    switch (action.type) {
        case CHANGE_LOCALE:
            return {
                ...state,
                locale: action.data.locale
            };
        case TOGGLE_SPINNER:
            return {
                ...state,
                showLoader:    action.data.showLoader,
                loaderMessage: action.data.loaderMessage
            };
        case SHOW_MESSAGE:
            return {
                ...state,
                message: action.data
            };
        case CLEAR_MESSAGE:
            return {
                ...state,
                message: null
            };
        case RESET_TERMINAL:
            return {
                ...initialState.app,
                apiClient:   state.apiClient,
                hotelConfig: state.hotelConfig
            };

        case SET_HOTEL_CONFIG:
            return {
                ...state,
                hotelConfig: action.data
            };

        case LOCATION_CHANGE:
            return {
                ...state,
                showLoader: false
            };
        case GENERATE_IDEMPOTENCY_KEY:
            let idempotencyKey = uuidv4();

            return {
                ...state,
                idempotencyKey: idempotencyKey
            };

        default:
            return state;
    }
};
