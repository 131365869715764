// APALEO
export const ON_LOAD_RESERVATION_SUCCESS = 'ON_LOAD_RESERVATION_SUCCESS';
export const ON_LOAD_RESERVATION_ERROR   = 'ON_LOAD_RESERVATION_ERROR';
export const ON_UPDATE_SIGNED_GUESTS     = 'ON_UPDATE_SIGNED_GUESTS';
export const ON_CHECKIN_ERROR            = "ON_CHECKIN_SUCCESS";
export const UPDATE_ROOMINFORMATION      = "UPDATE_ROOMINFORMATION";
export const TOGGLE_SPINNER              = 'TOGGLE_SPINNER';
export const SET_HOTEL_CONFIG            = 'SET_HOTEL_CONFIG';
export const RESERVATION_NEEDS_UPDATE    = 'RESERVATION_NEEDS_UPDATE';

// App
export const RESET_TERMINAL           = "RESET_TERMINAL";
export const SHOW_MESSAGE             = "SHOW_MESSAGE";
export const CLEAR_MESSAGE            = "CLEAR_MESSAGE";
export const GENERATE_IDEMPOTENCY_KEY = 'GENERATE_IDEMPOTENCY_KEY';
export const CHANGE_LOCALE            = 'CHANGE_LOCALE';

// CART
export const SERVICES_ADD    = 'SERVICES_ADD';
export const SERVICES_REMOVE = 'SERVICES_REMOVE';
export const EMPTY_CART = 'EMPTY_CART';

export const DEFAULT_MESSAGE_TIMEOUT = 8000;
