import React, {Component} from "react";


import Translator          from "../components/Translator";
import * as moment         from "moment";
import {IMask, IMaskInput} from "react-imask";
import counterpart         from "counterpart";

class ManualDateInput extends Component
{
    initialClassState = "form-group";

    constructor(props)
    {
        super(props);
        this.state = {
            date:       "",
            fieldClass: (this.props.value.length > 0 ? 'filled' : '')
        }
    }

    handleClick = (e) => {

    }

    attachFocus = () => {
        this.setState({
                          fieldActive: true
                      });
    }

    detachFocus = () => {
        this.setState({
                          fieldActive: false
                      });
    }

    componentDidMount()
    {
        document.addEventListener('click', this.handleClick, false);
        let value = null;
        if (this.props.value !== null && this.props.value !== '') {
            value = moment(this.props.value).format('DD.MM.YYYY');
            this.setState({date: value});
        }
        this.input.addEventListener('focus', this.attachFocus);
        this.input.addEventListener('blur', this.detachFocus);
    }

    componentWillUnmount()
    {
        document.removeEventListener('click', this.handleClick, false);
        this.input.removeEventListener('focus', this.attachFocus);
        this.input.removeEventListener('blur', this.detachFocus);
    }

    onChange = (date) => {
        let value  = moment(date, "DD.MM.YYYY");
        let format = value.format('YYYY-MM-DD');

        if (!value.isValid()) {
            format = '';
        }

        let event = {
            target: {
                name:  this.props.name,
                value: format
            }
        }
        this.props.onChange(event);
    }

    render()
    {
        let reqSpan = null;
        if (this.props.required) {
            reqSpan = <span className="required">*</span>;
        }
        let className       = (this.state.fieldClass) + (this.state.fieldActive ? " field-active" : "");
        let isInputRequired = false;

        if (this.props.required && this.props.error && !moment(this.state.date, "DD.MM.YYYY").isValid()) {
            isInputRequired = true;
        }

        if (this.state.date !== '') {
            className = 'filled';
        }

        let placeholder = "TT.MM.JJJJ";
        if(counterpart('thisIso') === "en-EN"){
            placeholder = "DD.MM.YYYY";
        }

        return (
            <div className={'floating-input'} ref={node => this.formRef = node}>
                <div className={"field-group"}>
                    <div className={"__input-field"}>
                        <label className={className}>
                            <Translator content={this.props.label} />{reqSpan}
                        </label>
                        <IMaskInput
                            placeholder={this.state.fieldActive ? placeholder : ""}
                            className={"floating-label " + (isInputRequired ? "_required" : "") +
                                (this.state.fieldActive ? "active " : "")}
                            mask={Date}
                            radix="."
                            value={this.state.date}
                            unmask={false}
                            inputRef={target => this.input = target}
                            onAccept={
                                (value, mask) => {
                                    this.setState({date: value}, () => {
                                        this.onChange(value)
                                    });
                                }
                            }
                            disabled={this.props.readOnly}
                            required={this.props.required}
                            pattern={'DD.MM.YYYY'}
                            lazy={true}
                            format={function(date) {
                                return moment(date).format('DD.MM.YYYY');
                            }}
                            parse={function(str) {
                                return moment(str, 'DD.MM.YYYY');
                            }}
                            blocks={
                                {
                                    YYYY: {
                                        mask: IMask.MaskedRange,
                                        from: 1900,
                                        to:   9999
                                    },
                                    MM:   {
                                        mask: IMask.MaskedRange,
                                        from: 1,
                                        to:   12
                                    },
                                    DD:   {
                                        mask: IMask.MaskedRange,
                                        from: 1,
                                        to:   31
                                    }
                                }
                            }
                        />
                    </div>
                </div>
            </div>
        );
    }
}

ManualDateInput.defaultProps = {
    label:        '',
    initialValue: '',
    readOnly:     false,
    required:     false,
    error:        false,
    type:         'text'
};

export default ManualDateInput;
