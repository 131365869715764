import React, {Component} from 'react';
import {connect}          from "react-redux";
import {clearMessage}     from "../../redux/actions";
import Translator         from "../Translator";

class LoadingSpinner extends Component
{

    contentOfSpinner()
    {
        if (this.props.showLoader) {
            return <div className="loadingspinner">
                <div className="__content">
                    {(() => {
                        if (this.props.loaderMessage) {
                            return <Translator className={'__message'} component={"div"} content={this.props.loaderMessage}/>;
                        }
                    })()}
                    <div className="loader"></div>
                </div>
            </div>;
        } else {
            return null;
        }
    }

    render()
    {
        return this.contentOfSpinner();
    }
}

export default connect(state => {
    return {
        showLoader:    state.app.showLoader,
        loaderMessage: state.app.loaderMessage
    }
}, {clearMessage})(LoadingSpinner);
