import React      from "react";
import Translator from "../Translator";
import Modal      from 'react-modal';
import Icon       from "../Icon";
import {connect}  from "react-redux";

class MeldescheinHelpModal extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {
            modalIsOpen: {
                support: this.props.isModalOpen
            }
        };
    }

    render()
    {
        return (<Modal
            className={'meldeschein-help-modal'}
            isOpen={this.props.isModalOpen}
            shouldCloseOnOverlayClick={true}
            onRequestClose={this.props.closeModal}
        >
            <div onClick={this.props.closeModal} className="modal-close">
                <Icon icon="MOOI_x"/>
            </div>
            <div className="modal-headline">
                <Translator content={'supportModal.header'}/>
            </div>
            <div className="modal-content">
                <Translator content={'signature.information'}/>
            </div>
        </Modal>)
    }
}

export default (connect(state => {
    return {
        specificallyCustomerInformation: state.app.specificallyCustomerInformation
    }
})(MeldescheinHelpModal));
