import React, {Component}     from 'react';
import {Redirect, withRouter} from "react-router";
import {connect}              from "react-redux";
import Translator             from "../../components/Translator";
import Header                 from "../../components/Header";
import Footer                 from "../../components/Footer";

class Thankyou extends Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            redirect: false
        };

        setTimeout(() => {
            this.setState({redirect: true})
        }, 5000);
    }

    render()
    {
        if (this.state.redirect) {
            return (<Redirect refresh={true} to="/" />);
        }
        return (<div className="page page-thankyou">
            <Header
                showSteps={4}
            />
            <div className="app-content">
                <div className="container">
                    <div className="thankyou">
                        {(() => {
                            if (!this.props.pagesConfig.skipCheckin && this.props.reservation) {
                                let primaryGuest = this.props.reservation.primaryGuest;
                                let firstName    = process.env.REACT_APP_FIRSTNAME ?? '';
                                let lastName     = process.env.REACT_APP_LASTNAME ?? '';
                                let name         = '';
                                if (this.props.pagesConfig.thankyou.showName) {
                                    if (typeof (primaryGuest) !== 'undefined') {
                                        firstName = primaryGuest.firstName ?? '';
                                        lastName  = primaryGuest.lastName ?? '';
                                    }
                                    name = firstName + ' ' + lastName;
                                }

                                return <p className="__headline">
                                    <Translator content={"thankyou.headline"}
                                                with={{name: name}} />
                                </p>;
                            }
                        })()}
                        <p className="__text"><Translator content={"thankyou.text"} /></p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>);
    }
}

export default withRouter(connect(state => {
    return {
        pagesConfig: state.app.pagesConfig,
        reservation: state.apaleo.reservation
    }
}, {})(Thankyou));
