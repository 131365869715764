import React, {Component} from 'react';
import Translator         from "./Translator";
import {connect}          from "react-redux";
import {clearMessage}     from "../redux/actions"
import Icon               from "./Icon";

class MessageHandler extends Component
{
    constructor(props)
    {
        super(props);
        this.timeout = null;
    }

    componentDidUpdate(prevProps)
    {
        if (this.props.message !== null) {
            clearTimeout(this.timeout);
            this.timeout = null;

            if (this.props.message.timeout !== null && this.props.message.timeout > 0) {
                this.timeout = setTimeout(() => {
                    this.props.clearMessage();
                }, this.props.message.timeout);
            }
        }
    }

    renderError()
    {
        return <div className={"message _" + this.props.message.type}>
            <Icon icon={'clear'} onClick={this.props.clearMessage}/>
            <h5>
                <Translator content="messages.errors.errorTitle"/>
            </h5>
            <Translator content={this.props.message.message}/>
        </div>;
    }

    renderDefault()
    {
        return <div className={"message _" + this.props.message.type}>
            <Icon icon={'clear'} onClick={this.props.clearMessage}/>
            <Translator content={this.props.message.message}/>
        </div>;
    }

    render()
    {
        if (null === this.props.message) {
            return null;
        }

        if (this.props.messageType === 'error') {
            return this.renderError();
        }

        if (this.props.messageType === 'timeout') {
            return this.renderDefault();
        }

        return this.renderDefault();
    }
}

export default connect(state => {
    return {
        message: state.app.message
    }
}, {clearMessage})(MessageHandler);
