import React, {Component} from 'react';
import Translator         from "../../components/Translator";
import {connect}          from "react-redux";
import {withRouter}       from "react-router";

/**
 * @deprecated
 */
class Help extends Component
{
    render()
    {
        return (<div className="page page-help">
                <div className="app-content">
                    <div className="bodytext-app"><Translator content={"help.hotline"}/> <span
                        className="bigger"><Translator content={"help.number"}/></span></div>
                </div>
            </div>);
    }
}

export default withRouter(connect(state => {
    return {
        pagesConfig: state.app.pagesConfig
    }
}, {})(Help));
