import React, {Component} from 'react';
import {Route, Switch}    from 'react-router-dom';
import {ConnectedRouter}  from 'connected-react-router';
import Start              from "../pages/Start/Start";
import Main               from "../pages/Main/Main";
import Login              from "../pages/Checkin/Login";
import Address            from "../pages/Checkin/Address";
import Citytax            from "../pages/Checkin/Citytax";
import PaymentPage        from "../pages/Payment/PaymentPage";
import RoomInformation    from "../pages/Checkin/RoomInformation";
import LostCard           from "../pages/Lostcard/LostCard";
import Thankyou           from "../pages/Thankyou/Thankyou";
import Help               from "../pages/Help/Help";
import {history}          from "../redux/store";
import Details            from "../pages/Checkin/Details";

class AppRoutes extends Component
{
    render()
    {
        return (<ConnectedRouter history={history}>
            <Switch>
                <Route path="/" exact component={Start}/>
                <Route path="/:locale/" exact component={Main}/>

                <Route path="/:locale/checkin/login" component={Login}/>
                <Route path="/:locale/checkin/details" component={Details}/>
                <Route path="/:locale/checkin/address" component={Address}/>
                <Route path="/:locale/checkin/citytax" component={Citytax}/>
                <Route path="/:locale/checkin/payment" component={PaymentPage}/>
                <Route path="/:locale/checkin/roominformation" component={RoomInformation}/>

                <Route path="/:locale/lostcard" component={LostCard}/>
                <Route path="/:locale/thankyou" component={Thankyou}/>
                <Route path="/:locale/help" component={Help}/>
            </Switch>
        </ConnectedRouter>);
    }
}

export default AppRoutes;
