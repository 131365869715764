import React from "react";

export const reduceCharges = (p, key, charge, locale) => {
    if (!p.hasOwnProperty(key)) {
        let item  = {};
        item.name = <span>{charge.name}</span>;

        if (typeof (charge.translatedNames) !== 'undefined' && charge.translatedNames !== null && typeof (charge.translatedNames[locale]) !==
            'undefined') {
            item.name = <span>{charge.translatedNames[locale]}</span>;
        }

        item.serviceType  = charge.serviceType;
        item.serviceDates = [charge.serviceDate];
        item.price        = charge.amount.grossAmount;
        item.priceSingle  = charge.amount.grossAmount;
        item.currency     = charge.amount.currency;
        p[key]            = item;
        p[key].count      = 1;
    } else {
        p[key].count++;
        p[key].serviceDates.push(charge.serviceDate);
        p[key].serviceDates.sort();
        p[key].price += charge.amount.grossAmount;
    }
    return p;
}


export const getRefundedChargesList = (folio) => {
    // get list of refunded charges (ids)
    let refundedCharges = [];
    if (typeof folio.allowances !== 'undefined') {
        for (let i = 0; i < folio.allowances.length; i++) {
            let allowance = folio.allowances[i];
            refundedCharges.push(allowance.sourceChargeId);
        }
    }
    return refundedCharges;
}
