import React                         from 'react';
import Header                        from "../../components/Header";
import Translator                    from "../../components/Translator";
import moment                        from "moment/moment";
import 'moment/locale/de';
import {Redirect, withRouter}        from "react-router";
import {connect}                     from "react-redux";
import BackButton                    from "../Buttons/BackButton";
import Footer                        from "../../components/Footer";
import {goToAdressPage, goToCitytax} from "../Types/Dispatcher";
import ReservationContainer          from "../../components/reservation/ReservationContainer";
import AddExtrasModal                from "../../components/Modal/AddExtrasModal";
import Icon                          from "../../components/Icon";

class Details extends React.Component
{

    constructor(props)
    {
        super(props);
        this.state = {
            ...this.state,
            modalIsOpen: false
        };

        //TODO: change state
        let counterpart = require('counterpart');
        moment.locale(counterpart.getLocale());

        this.openModal  = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    openModal()
    {
        this.setState({modalIsOpen: true});
    }

    closeModal()
    {
        this.setState({modalIsOpen: false});
    }

    render()
    {
        if (!this.props.reservation.id) {
            return <Redirect to={'login'} />;
        }
        let folio = this.props.reservation._meta.folio;

        return (<div className="page page-details">
            <Header
                showSteps={1}
            />
            <div className="app-content">
                <div className="container">
                    <div className={'page-header'}>
                        <Translator content={'details.header'} />
                    </div>
                    <ReservationContainer />
                </div>
                <div className="container">
                    {(() => {
                        if (folio !== null) {
                            return <>
                                <div className={(this.props.serviceOffers.length > 0) ? 'add-extras'
                                                                                      : 'add-extras _disabled'}>
                                    <button
                                        disabled={this.props.serviceOffers.length === 0}
                                        onClick={() => {
                                            this.openModal()
                                        }}>
                                        <Icon icon={'MOOI_plus'} /><Translator content={'button.addExtras'} />
                                    </button>
                                </div>
                                <AddExtrasModal isModalOpen={this.state.modalIsOpen} closeModal={this.closeModal} /></>;
                        } else {
                            // just a spacer
                            return <div className={"add-extras _disabled"} />
                        }
                    })()}
                    <div className="button-row">
                        <BackButton />
                        {(() => {
                            let meldescheineExists = this.props.reservation._meta.meldescheineExists;

                            let noInformationNeeded = meldescheineExists[-1];

                            if (!this.props.meldescheinConfig.onlyForPrimaryGuest) {
                                for (let i = 0; i < Object.keys(meldescheineExists).length; i++) {
                                    if (!meldescheineExists[i]) {
                                        noInformationNeeded = false;
                                        break;
                                    }
                                }
                            }

                            let translator = <Translator content={'button.next'} />;
                            let onClickFct = () => goToAdressPage();

                            if (noInformationNeeded) {
                                if (this.props.pagesConfig.skipCheckin) {
                                    // translator = <Translator content={'button.noInformationNeeded'}/>;
                                    translator = undefined;
                                } else if (this.props.pagesConfig.skipCitytax && this.props.pagesConfig.skipPayment) {
                                    translator = <Translator content={'button.checkin'} />;
                                } else if (!this.props.pagesConfig.skipCitytax &&
                                    this.props.reservation._meta.need_travelreason) {
                                    onClickFct = () => goToCitytax();
                                }
                            } else {
                                translator = <Translator content={'button.checkInformation'} />;
                            }

                            if (translator) {
                                return <button
                                    onClick={onClickFct}
                                    className="btn btn-primary btn-uppercase btn-fullwidth btn-space"
                                >
                                    {translator}
                                </button>;
                            }
                        })()}
                    </div>
                </div>
            </div>
            <Footer />
        </div>)
    }
}

export default withRouter(connect(state => {
    return {
        serviceOffers:     state.apaleo.serviceOffers,
        meldescheinConfig: state.app.meldescheinConfig,
        reservation:       state.apaleo.reservation,
        pagesConfig:       state.app.pagesConfig,
        locale:            state.app.locale
    }
}, {})(Details));
