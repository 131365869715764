import React, {useState}       from "react";
import {Trans, useTranslation} from "react-i18next";
import i18next                 from "i18next";

const Translator        = (props) => {
    const {t}                     = useTranslation();
    const [rerender, setRerender] = useState(false);
    i18next.on('languageChanged', function(lng) {
        setRerender(!rerender);
    })

    let TagName = props.component;

    return (<TagName onClick={props.onClick} className={props.className}><Trans
        t={t}
        i18nKey={props.content}
        components={props.components}
        values={props.with} /></TagName>);
}
Translator.defaultProps = {
    content:    '',
    with:       '',
    className:  '',
    components: null,
    component:  'span',
    onClick:    () => {
    }
};

export default Translator;
