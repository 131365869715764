import React, {Component}                        from 'react';
import Translator                                from "../../components/Translator";
import {withRouter}                              from "react-router";
import {connect}                                 from "react-redux";
import {onLoadReservationSuccess, toggleSpinner} from "../../redux/actions";
import * as Sentry                               from '@sentry/browser';

class TerminalVerification extends Component
{

    constructor(props)
    {
        super(props);

        this.state = {
            verificationCode: '' // BER-1-TEST'
        };

        this.handleSubmit           = this.handleSubmit.bind(this);
        this.handleChange           = this.handleChange.bind(this);
        this.submitVerificationCode = this.submitVerificationCode.bind(this);
    }

    componentDidMount = () => {
        // ?verificationCode=DETCO-TEST-GLUTZ-DEV
        let verificationCode = new URLSearchParams(window.location.search).get('verificationCode');
        if (verificationCode !== null && verificationCode !== '') {
            this.setState({verificationCode: verificationCode.toUpperCase()}, () => {
                this.submitVerificationCode();
            });
        }
    }

    handleSubmit(event)
    {
        event.preventDefault();
        event.stopPropagation();

        this.submitVerificationCode();
    }

    handleChange(event)
    {
        this.setState({verificationCode: (event.target.value).toUpperCase()});
    }

    submitVerificationCode()
    {
        localStorage.removeItem('verificationCode');

        if (this.state.verificationCode !== '') {
            this.props.toggleSpinner(true, '');

            this.props.apiClient.verifyTerminalCode(this.state.verificationCode).then(response => {
                this.props.toggleSpinner(false, '');
                if (response.error) {
                    return false;
                } else if (response === true) {
                    localStorage.setItem('verificationCode', this.state.verificationCode);
                    this.props.checkStorageForTerminalCode();
                }
            }).catch((err) => {
                console.warn(err);
                Sentry.captureException(err);
                this.props.toggleSpinner(false, '');
            });
        }
    }

    render()
    {
        return (<div className="page page-terminalVerification">
            <div className="app-content">
                <div className="bodytext-app"><span className="bigger"><Translator
                    content={"pageTitles.verificationCode"} /></span>
                </div>
                <div className="row">
                    <div className="col-12">
                        <form className="terminal-form" onSubmit={this.handleSubmit}>
                            <div className="form-group">
                                <div className="">
                                    <input type="text"
                                           className="form-control"
                                           value={this.state.verificationCode}
                                           onChange={this.handleChange}
                                           autoComplete="off"
                                           placeholder=" " />
                                    <i className="icon icon-terminal-group hidden" />
                                </div>
                            </div>
                            <button
                                className="btn btn-primary btn-uppercase btn-fullwidth btn-space">
                                <Translator content={"verificationCode.buttons.submit"} /> <i
                                className="icon icon-terminal-arrow-r" /></button>
                        </form>
                    </div>
                </div>
            </div>
        </div>);
    }

}

export default withRouter(connect(state => {
    return {
        apiClient:   state.app.apiClient,
        pagesConfig: state.app.pagesConfig
    }
}, {onLoadReservationSuccess, toggleSpinner})(TerminalVerification));
