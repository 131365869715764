// @flow
import React, {Component}                                                from 'react';
import {connect}                                                         from 'react-redux';
import {Redirect, withRouter}                                            from "react-router";
import {checkInNow, toggleSpinner, updateSignedGuests}                   from "../../redux/actions";
import {goToCitytax, goToPaymentPage, goToRoominformation, goToThankyou} from "../Types/Dispatcher";
import * as Constants                                                    from "../../constants/constants";
import RegistrationForm                                                  from "../../components/forms/RegistrationForm";
import Footer                                                            from "../../components/Footer";
import type {
    SignedGuest
}                                                                        from "detco-check-in-terminal-js-client/build/Client";
import PropTypes                                                         from "prop-types";
import Header                                                            from "../../components/Header";

class Address extends Component
{

    static contextTypes = {
        router: PropTypes.object.isRequired
    };

    constructor(props)
    {
        super(props);
        this.getCheckIndex = this.getCheckIndex.bind(this);

        this.state = {
            // -1 means primaryGuest
            guestToDisplay: this.getCheckIndex(-1),
            signedGuests:   []
        };

        this.guestUpdated          = this.guestUpdated.bind(this);
        this.leaveAddressPage      = this.leaveAddressPage.bind(this);
        this.countAdditionalGuests = this.countAdditionalGuests.bind(this);
        this.componentDidMount     = this.componentDidMount.bind(this);
    }

    componentDidMount()
    {
        let countAdditionalGuests = this.countAdditionalGuests();
        if ((this.state.guestToDisplay >= 0 && this.props.meldescheinConfig.onlyForPrimaryGuest) ||
            this.state.guestToDisplay > countAdditionalGuests - 1) {
            //TODO: 1590502313 modal mit information, dass alle meldescheine ausgefüllt wurden und am Terminal keine mehr ausgefüllt werden müssen
            this.leaveAddressPage();
        }
    }

    async guestUpdated(updatedGuest: SignedGuest)
    {
        let newIndex = this.getCheckIndex(this.state.guestToDisplay + 1);

        let signedGuests = this.state.signedGuests;
        signedGuests.push(updatedGuest);

        let countAdditionalGuests = this.countAdditionalGuests();

        this.props.toggleSpinner(false, '');
        if (newIndex < countAdditionalGuests && !this.props.meldescheinConfig.onlyForPrimaryGuest) {
            this.setState({
                              guestToDisplay: newIndex,
                              signedGuests:   signedGuests
                          })
        } else {
            this.props.updateSignedGuests(signedGuests);
            this.leaveAddressPage();
        }
    }


    getCheckIndex(index)
    {
        let newIndex;

        // check for reservation first
        if (typeof this.props.reservation === 'undefined') {
            return 0;
        }

        let meldescheine = this.props.reservation._meta.meldescheineExists;
        if (meldescheine[index]) {
            for (let i = 0; i < Object.keys(meldescheine).length; i++) {
                if (!meldescheine[i]) {
                    newIndex = i;
                    break;
                }
            }
        } else {
            newIndex = index;
        }

        return newIndex;
    }

    leaveAddressPage()
    {
        let thisPage = this;
        this.props.toggleSpinner(true, '');
        if (this.props.pagesConfig.skipCitytax && this.props.pagesConfig.skipPayment) {
            this.props.checkInNow(Constants.PAGE_ADDRESS).then((response) => {
                if (response.status === 'checkinSkipped') {
                    goToThankyou(Constants.PAGE_ADDRESS);
                } else {
                    goToRoominformation(Constants.PAGE_ADDRESS);
                }
            }).catch(function(response) {
                thisPage.context.router.history.goBack();
            });
        } else if (this.props.pagesConfig.skipCitytax || !this.props.reservation._meta.need_travelreason) {
            goToPaymentPage(Constants.PAGE_ADDRESS);
        } else {
            goToCitytax(Constants.PAGE_ADDRESS);
        }
    }

    render()
    {
        if (!this.props.reservation.id) {
            return <Redirect to={'login'}/>;
        }
        let countAdditionalGuests = this.countAdditionalGuests();

        let form = null;

        if ((this.state.guestToDisplay >= 0 && this.props.meldescheinConfig.onlyForPrimaryGuest) ||
            this.state.guestToDisplay > countAdditionalGuests - 1) {
            form = null;
        } else {
            form = <RegistrationForm
                onGuestSend={this.guestUpdated}
                guestIndex={this.state.guestToDisplay}
            />;
        }
        return (<div className="page page-address">
            <Header showSteps={2}/>
            <div className="app-content">
                {form}
            </div>
            <Footer/>
        </div>);

    }

    countAdditionalGuests()
    {
        let count = 0;
        if (this.props.reservation.additionalGuests) {
            count = this.props.reservation.additionalGuests.length;
        }
        return count;
    }
}

// @formatter:off
export default withRouter(connect(state => {
    return {
        apiClient: state.app.apiClient,
        guestCredentials: state.apaleo.guestCredentials,
        pagesConfig: state.app.pagesConfig,
        meldescheinConfig: state.app.meldescheinConfig,
        reservation: state.apaleo.reservation
    }
}, {
    updateSignedGuests,
    toggleSpinner,
    checkInNow,
    goToThankyou
})(Address));
