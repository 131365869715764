import React      from "react";
import Translator from "../../Translator";
import Icon       from "../../Icon";
import moment     from "moment/moment";
import {connect}  from "react-redux";

const ReservationOverview = (props) => {
    return <div className={'__reservation-overview'}>
        <div className={'__booking-id'}>
            <Translator className={'__label'} content={'fields.bookingId'}/>
            <span className={'__id'}>{props.reservation.id}</span>
        </div>
        <div className="__dates">
            <div className="__date">
                <div className={'__icon'}>
                    <Icon icon={'MOOI_Icon_Pfeil-rechts-unten'} size={20}/>
                    <Translator
                        className={'__label'}
                        content="arrival"
                    />
                </div>
                <span
                    className={"__heading-date"}>{moment(new Date(props.reservation.arrival))
                    .format('LL')}</span>
            </div>
            <div className={'glue'}>
                -
            </div>
            <div className="__date">
                <div className={"__icon"}>
                    <Icon icon={'MOOI_Icon_Pfeil-rechts-oben'} size={20}/>
                    <Translator
                        className={'__label'}
                        content="departure"
                    />
                </div>
                <span
                    className={'__heading-date'}>{moment(new Date(props.reservation.departure))
                    .format('LL')}</span>
            </div>
        </div>
    </div>;
}

export default connect(state => {
    return {
        reservation: state.apaleo.reservation
    }
}, {})(ReservationOverview);
