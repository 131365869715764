import React        from "react";
import PropTypes    from "prop-types";
import IcomoonReact from "icomoon-react";
import iconSet      from "../config/icomoon/selection.json";

const Icon = props => {
    const {
              color,
              size,
              icon,
              className,
              onClick
          } = props;
    return (<IcomoonReact
        onClick={onClick}
        className={className}
        iconSet={iconSet}
        color={color}
        size={size}
        icon={icon}
    />);
};

Icon.propTypes = {
    icon:      PropTypes.string.isRequired,
    className: PropTypes.string,
    color:     PropTypes.string,
    size:      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

Icon.defaultProps = {
    onClick:   null,
    className: "svg-icon",
    color:     "",
    size:      40
};

export default Icon;
